// Env
import { envmode } from './Env';

// Config
import { getNetworkId } from './config/utils';
import { defaultUserState } from './config/config';
import { CollectionDetails, Network } from './config/collections.interface';
import { TestTokenObject } from './config/shop';

// React
import React, { ReactNode, useCallback, useEffect, useState } from 'react';

// Ionic
import { Storage } from '@ionic/storage';
import { IonAlert, IonToast, useIonAlert, useIonRouter } from '@ionic/react';

// Interfaces
import User, { UserState } from './interfaces/User.interface';
import { ERC20tokenABI, UniswapERC20Token } from './interfaces/Uniswap.Interface';
import { TokenResult } from './interfaces/Etherscan.interface';

// Colyseus
import { TokenMetadata } from './generated/TokenMetadata';

// Services
import { getUniswapTokens } from './services/Uniswap';
import { getCollectibleCount, fetchTokenMetadata, promiseDelay, getAccountsAndSign } from './services/Wallet';

// Components
import { cardDefaultHealth } from './components/play/PlayerCard';

// Web3
import Web3, { WalletProviders, Web3Provider, Web3Providers } from './web3/web3';

// Communications
import * as Colyseus from "colyseus.js";

// Types
import { GameModeTypesInterface } from './types/GameModes.type';

// Schema
import { ShopItem } from './generated/ShopItem';
import { Stats } from './generated/Stats';

// Components
import WaitModal from './components/ui/WaitModal';
import H5AudioPlayer from 'react-h5-audio-player';
import WalletInfo from './components/ui/WalletInfo';
import ColosseumBackdrop from './components/ui/ColosseumBackdrop';

// Supabase
import { getPlayerByWallet, signInAnonymouslyWithWallet, signOutUser, userLoggedIn } from './supabase/auth';
import { getAvatarURLfromStorage, getInGameCurrencyBalance, getPurchases } from './supabase/api';

// Config
import { CollectionSlug, initCollections } from './config/collections';
import { ShopItemRow } from './supabase/types';

// Prepare local storage
const storage = new Storage();
storage.create();

/**
 * Log user out
 */
const logoutUser = async () => {

    await storage.clear().then( async () => {
        await signOutUser()
        window.location.reload();
    });    
}

export const UserContext = React.createContext(defaultUserState);

type ContextProviderProps = {
    children: ReactNode;
};

const UserContextProvider: React.FC<ContextProviderProps> = ({ children }) => {

    const router = useIonRouter()
    const [loggedIn, setLoggedIn] = useState<boolean>(false)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [newVersion, setNewAppVersion] = useState<boolean>(false)
    const [userState, setUserState] = useState<UserState>(defaultUserState)
    const [connectingWallet, setConnectingWallet] = useState<boolean>(false)
    const [collections, setCollections] = useState<CollectionDetails[]>([])
    const [tokensLoaded, setTokensLoaded] = useState<boolean>(false)
    const [mode, setMode] = useState<GameModeTypesInterface | null>(null)
    const [settings, setSettings] = useState<boolean>(false);
    const [audioPlaying, setAudioPlaying] = useState<boolean>(false);
    const [musicPlayer, setMusicPlayer] = useState<H5AudioPlayer | null>(null);
    const [walletInfo, setWalletInfo] = useState<boolean>(false);
    const [presentAlert] = useIonAlert();
    const [demo, setDemo] = useState<boolean>(false);
    const [zeroXModal, setZeroXModal] = useState<boolean>(false);
    const [shopItems, setShopItems] = useState<ShopItemRow[]>([]);
    const [waitModal, setWaitModal] = useState<boolean>(false);
    
    // Collectibles
    const [displayCollectiblesLoaded, setDisplayCollectiblesLoaded] = useState<boolean>(false)
    const [collectibleCount, setCollectibleCount] = useState<number>(0);
    const [collectiblesLoaded, setCollectiblesLoaded] = useState<number>(0);

    // Alert Modal
    const [alertModal, setAlertModal] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [alertHeader, setAlertHeader] = useState<string>('');

    // Tooltip
    const [tooltip, setTooltip] = useState<{ type: any, data: any }>({ type: null, data: null });
    const [showTooltip, setShowTooltip] = useState<boolean>(false);

    // Web3 Provider
    const [provider, setProvider] = useState<Web3Provider | null>(null);

    // Network
    const [networkMessage, setNetworkMessage] = useState<string>('');
    const [showNetworkAlert, setShowNetworkAlert] = useState<boolean>(false);
    const [testnet, setTestnet] = useState<boolean>(false);

    // Theme
    const [chooseTheme, setChooseTheme] = useState<boolean>(false);
    const [theme, setTheme] = useState<CollectionSlug | null>(null);
    const [themeChangeOverlay, setThemeChangeOverlay] = useState<boolean>(false);

    /**
     * Check Version of App
     */
    // const checkAppVersion = () => {
    //     try {
    //         const domain = `${process.env.REACT_APP_GLADIATOR_DOMAIN}`.replaceAll('https://', '').replaceAll('http://', '');
    //         externalRequest(`//${domain}`).get(`${app()}latest-tag.json?u=${moment().unix()}`, { timeout: 10000 })
    //         .then(async (res: AxiosResponse) => {
    //             const current = git?.default?.tag;
    //             const response = res.data.tag;
    //             console.log('Client', current)
    //             console.log('Server', response)
    //             if ((current !== response) && (current < response) && !newVersion) {
    //                 setNewAppVersion(true)
    //             }
    //         }).catch((error: any) => {
    //             console.log('error', error);
    //         });          
    //     } catch (error) {
    //         console.log('error', error);
    //     }
    // }

    /**
     * Choose Web3 Provider
     * @param boolean
     * @returns promise with boolean
     */
    const chooseProvider = async (p: Web3Provider, signature: boolean = true) => {
      
        // startLoading(`Connecting wallet and loading NFTS...`)
        setWaitModal(true)

        // Clone state
        let state = userState;

        // Begin Web3 Request and pass along the provider object
        const web3 = new Web3(p.provider)

        try {
            await web3.eth.requestAccounts().then( async () => {

                // Display collectibles loaded progress modal
                setDisplayCollectiblesLoaded(true)

                // Set Provider
                setProvider(p)

                // Get Chain ID
                const chain: number = await p.provider.request({ method: 'net_version' });
                const chainID = parseInt(chain.toString())

                // Update cloned user state with: provider, web3 instance, and chain ID
                state.user.provider = p;
                state.user.web3 = web3;
                state.user.chainID = chainID;

                // If we are in dev mode, we are checking for testnet
                if (envmode === 'dev') {

                    // If the chain ID is not sepolia, display an error using useIonAlert
                    if (chainID !== getNetworkId(Network.Sepolia)) {
                        setNetworkMessage('Please make sure you\'re connected to Sepolia Testnet');
                        setShowNetworkAlert(true)
                        return
                    }

                // If we are in production mode, we are checking for mainnet
                } else {

                    // If the chain ID is not ETH Mainnet, display an error using useIonAlert
                    if (chainID !== 1) {
                        setNetworkMessage('Please make sure you\'re connected to ETH Mainnet');
                        setShowNetworkAlert(true)
                        return
                    }

                }

                // Get Wallet Address and Sign Message (if signature is true)
                await getAccountsAndSign(state.user, signature).then( async (newUserState) => {                
                    signIn(state, newUserState, p)
                }).catch((error: any) => {

                    setWaitModal(false)
                    if (error.code === 4001) {
                        setAlertHeader('Wallet Connection Error')
                        setAlertMessage('User rejected wallet connection request.')
                    } else {                
                        setAlertHeader('Wallet Connection Error')
                        setAlertMessage(`${error.message}`)
                    }
                    setAlertModal(true)

                })

            }).catch(error => {
                console.log('error', error)
                setWaitModal(false)
                presentAlert({
                    header: 'Wallet Connection Error',
                    message: 'Please check wallet connection settings to continue.',
                    buttons: ['OK']
                });
            });
        } catch (error: any) {
            setWaitModal(false)
            switch (error.code) {
            case 4001:
                console.log('User rejected wallet connection request.');
                break;
            }
        }

    }

    /**
     * Play Demo
     */
    const playDemo = () => {

        setWaitModal(true)
        setDisplayCollectiblesLoaded(true)
        setDemo(true)

        // Get Chain and setup wallet state after connection
        getAccountsAndSign(userState.user, false, true).then( async (newUserState) => {
            signIn(userState, newUserState, { 
                name: WalletProviders.Demo,
                id: 'DM',
                provider: null,
                connect: false,
            })
        }).catch((error: any) => {
            console.log('error', error);  
        });

    }

    /**
     * Sign in with Wallet
     * @param state UserState
     * @param newUserState User
     * @param p Web3Provider
     */
    const signIn = async (state: UserState, newUserState: User, p: Web3Provider) => {

        if (!newUserState.wallet) {
            console.warn('No wallet address found')
            return
        }

        // Sign in / register
        await signInAnonymouslyWithWallet(newUserState.wallet, p).then( async (player) => {

            // Set the player object
            newUserState.player = player

            // Get gameplay collections
            await initCollections().then((collectionArray) => {
            
                // Set collections
                setCollections(collectionArray)

                // Update state with new object
                state.user = newUserState
    
                // Get tokens and metadata for this user
                getCollectibleCount(state.user, collectionArray).then(tokenResult => {

                    // Set collectible total count
                    setCollectibleCount(tokenResult.length)

                    // Get collectibles metadata
                    getCollectiblesMetadata(tokenResult).then(tokens => {

                        // Process collectibles and prepare for play
                        const preparedTokens: TokenMetadata[] = [];

                        // If the user doesn't own any tokens, set tokens loaded to 100%
                        if (tokenResult.length === 0) {

                            setCollectiblesLoaded(100)

                        // If the user owns tokens, prepare them for play
                        } else {
        
                            tokens.forEach((t) => {
                                const token = new TokenMetadata();
                                token.token_id = t.token_id;
                                token.name = t.name;
                                token.description = t.description;
                                token.image = t.image;
                                token.attributes = t.attributes;
                                token.flipped = t.flipped;
                                token.collection = t.collection;
                                token.stats = new Stats();
                                token.stats.defense = cardDefaultHealth;
                                token.stats.health = cardDefaultHealth;
                                preparedTokens.push(token);
                            });
        
                        }
                        
                        // console.log('preparedTokens', preparedTokens)
    
                        // Update user state with new collectibles
                        state.user.collectibles = preparedTokens;
    
                        // Update state
                        setUserState({...state});
    
                        // Wait a moment, close loading modal, and set tokens loaded to true to enter game
                        setTimeout(() => {
                            setTokensLoaded(true)
                            setWaitModal(false)
                            setDisplayCollectiblesLoaded(false)
                        }, 1000)
    
                        if (p.provider) {

                            // Account change
                            p.provider.on("accountsChanged", async () => {
                                console.log('accountsChanged')
                                // logout();
                            });
                        
                            // Network / Chain change
                            p.provider.on("chainChanged", async () => {
                                console.log('chainChanged')
                                // logout();
                            });
        
                            // Disconnect event
                            p.provider.on("disconnect", async () => {
                                logout();
                            })

                            console.log('Provider listeners started')

                        } else if (demo) {

                            console.log('Demo mode started')

                        }


                    })
    
                }) 

            });

        }); 

    }

    /**
     * Check if we are on the testnet
     */
    const checkTestnet = useCallback(async () => {
        // If it is demo mode, we are on the testnet
        if (demo) {
            setTestnet(true);
            return true;
        }
        const chain: number = await userState.user.provider?.provider.request({ method: 'net_version' });
        const chainID = parseInt(chain.toString())
        const testnet = chainID === getNetworkId(Network.Sepolia);
        setTestnet(testnet);
        return testnet;
    }, [demo, userState.user.provider?.provider]);

    /**
     * Change Theme
     * @param theme CollectionSlug
     */
    const changeTheme = (theme: CollectionSlug | null) => {

        const transitionTime = 1.5 * 1000

        // Show wait modal
        setWaitModal(true)

        // Enable overlay
        setThemeChangeOverlay(true)

        setTimeout(() => {

            // Hide choose theme modal
            setChooseTheme(false)

            setTimeout(() => {

            // Fade in overlay
            document.getElementById('theme-change-overlay')?.classList.add('fade-in')

                // When animation completes, continue
                setTimeout(() => {

                    // Set theme class on app container
                    const appContainer = document.querySelector('#root > ion-app')
                    if (appContainer) {
                        // Remove any existing theme classes
                        appContainer.classList.forEach((c) => {
                            if (c.startsWith('theme-')) {
                                appContainer.classList.remove(c)
                            }
                        })
                        appContainer.classList.add(`theme-${theme}`)
                    }

                    // Set theme
                    setTheme(theme)
                    // Save theme to storage
                    // storage.set('theme', theme)

                    // Fade out overlay
                    document.getElementById('theme-change-overlay')?.classList.add('fade-out')

                    // When animation completes, disable wait modal and theme modal
                    setWaitModal(false)
                    setChooseTheme(false)
                    setTimeout(() => {
                        setThemeChangeOverlay(false)
                    }, transitionTime / 2)

                }, transitionTime)

            }, 10)

        }, 10)

    }

    /**
     * Set Game Mode
     * @param s Socket Connection
     */
    const setGameMode = (mode: GameModeTypesInterface) => {
        let state = userState;
        state.user.mode = mode;
        setUserState({...state});
        setMode(null)
        setMode(mode)
    }

    /**
     * Set Socket Connection for User
     * @param s Socket Connection
     */
    const setSocket = (s: Colyseus.Client) => {
        let state = userState;
        state.user.socket = s;
        setUserState({...state});
    }

    /**
     * Clear storage and log user out
     */
    const logout = async () => {
        logoutUser()
    };

    /**
     * Get Collectibles
     * @param user User object
     * @param collectibles TokenMetadata[]
     * @returns TokenMetadata[]
     */
    const getCollectiblesMetadata = async (tokens: TokenResult[]): Promise<TokenMetadata[]> => {

        let loaded = 0;
        setCollectiblesLoaded(loaded)
        const metadataPromises = tokens.map(async (t, i) => {
            await promiseDelay(1 * i);
            const tokenID = Number(t.TokenId);
            return fetchTokenMetadata(t.Collection, tokenID).then(async (metadata) => {
                loaded++;
                const percentage = (loaded / tokens.length) * 100;
                const formattedPercentage = Number(percentage.toFixed());
                setCollectiblesLoaded(formattedPercentage);
                return metadata;
            });
        })
        const allMetadata = await Promise.all(metadataPromises);
        const collectibles = allMetadata.filter((m) => m !== undefined) as TokenMetadata[];
        return collectibles.length > 0 ? collectibles : [];

    }

    /**
     * Get Player Stats
     */
    const playerStats = useCallback(async () => {
        if (userState.user.wallet) {
            getPlayerByWallet(userState.user.wallet).then((player) => {
                if (player) {
                    let state = userState;
                    state.user.player = player;
                    setUserState({...state});
                }
            });
        }
    }, [userState]);

    /**
     * Balance Check
     */
    const balanceCheck = useCallback(async (testnet: boolean) => {

        // console.log('balanceCheck')

        // Balance object that aligns with the User interface
        const balance = {
            ethBalance: 0,
            erc20Balance: 0,
            gladiiBalance: 0
        }

        // Get ERC20 Balance
        const preferredERC20 = testnet ? 'LINK' : 'USDC';

        let erc20Token: UniswapERC20Token = {} as UniswapERC20Token;

        if (testnet) {
            erc20Token = await TestTokenObject.tokens.find(token => token.symbol === preferredERC20)!;
        } else {
            await getUniswapTokens(userState.user).then(async (tokens: any) => {
                erc20Token = tokens.find((token: UniswapERC20Token) => token.symbol === preferredERC20)!;
            });
        }

        if (!demo) {

            const tokenContract = new userState.user.web3.eth.Contract(ERC20tokenABI, erc20Token?.address);

            // Get ERC20 Balance
            const erc20balance = await tokenContract.methods.balanceOf(userState.user.wallet).call();
            const formattedErc20Balance = erc20balance / Math.pow(10, erc20Token?.decimals!);
                    

            // Get ETH Balance
            const ethBalance = await userState.user.web3.eth.getBalance(userState.user.wallet);
            const formattedEthBalance = userState.user.web3.utils.fromWei(ethBalance);
            

            // Update balance object
            balance.ethBalance = Number(formattedEthBalance);
            balance.erc20Balance = Number(formattedErc20Balance);

        }

        // Get In-Game Currency Balance
        if (userState.user.player) {
            const gladiiBalance = await getInGameCurrencyBalance(userState.user.player.uuid)
            balance.gladiiBalance = gladiiBalance?.total_currency ?? 0;
        }
        
        // Update user state with new balance object, but clone it first
        let state = userState;
        state.user.ethBalance = balance.ethBalance;
        state.user.erc20Balance = balance.erc20Balance;
        state.user.gladiiBalance = balance.gladiiBalance;
        setUserState({...state});

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [demo]);

    /**
     * Get Balance and Stats
     */
    const getBalanceAndStats = useCallback(async () => {
        checkTestnet().then(async (testnet) => {

            // Get Balance
            balanceCheck(testnet)
    
            // Get Player Stats
            playerStats()
            
            // Get Purchased Items
            getShopItems()

        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },  [balanceCheck, playerStats, checkTestnet])

    /**
     * Get Shop Items
     */
    const getShopItems = () => {
        getPurchases(userState.user).then((purchases) => {
            const shopItems: ShopItemRow[] = [];
            purchases?.forEach((p) => {
                const item = new ShopItem();
                item.id = p.id;
                item.type = p.type ?? '';
                item.name = p.name ?? '';
                item.description = p.description ?? '';
                item.collection = p.collection ?? '';
                item.cost_usdc = p.cost_usdc ?? 0;
                item.cost_eth = p.cost_eth ?? 0;
                item.cost_gladii = p.cost_gladii ?? 0;
                item.metadata = p.metadata as any;
                shopItems.push(item as ShopItemRow);
            })
            setShopItems(shopItems as ShopItemRow[]);
        }).catch((error) => {    
            console.error('Error getting shop items', error);
        });
    }

    /**
     * Check if the user is logged in
     */
    const checkAuth = useCallback(async () => {
        setTimeout(async () => {
            await userLoggedIn().then(async (loggedIn) => {
                if (loggedIn && loggedIn?.user.user_metadata?.provider) {
                    const provider = Web3Providers.find(p => p.name === loggedIn?.user.user_metadata?.provider)
                    if (provider && provider?.name !== 'Demo') {
                        chooseProvider(provider, false)
                    } else {
                        playDemo()
                    }
                }
            });
        }, 1100)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // useEffect(() => {
        // console.log('Logged In', loggedIn)
        // console.log('User State', userState)
    // }, [loggedIn, userState])

    useEffect(() => {

        // No wallet? No access
        if (!userState.user.wallet && router?.routeInfo.pathname !== '/') {
            router?.push('/')
        }

    }, [router, userState.user.wallet, router?.routeInfo.pathname])


    useEffect(() => {
        if (userState.user.wallet && userState.user.player?.uuid) {
            getBalanceAndStats()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userState.user.wallet, userState.user.player?.uuid])

    // useEffect(() => {
    //     checkAuth()
    // }, [checkAuth])

    return (
        <UserContext.Provider
            value={{

                // Logged In
                loggedIn,
                setLoggedIn,

                // Ion Router
                router,

                // User
                user: userState.user,

                // Wallet
                chooseProvider,
                connectingWallet,
                setConnectingWallet,
                tokensLoaded,
                walletInfo,
                setWalletInfo,   
                
                // Collections
                collections,
                setCollections,

                // Collectibles
                displayCollectiblesLoaded,
                collectibleCount,
                setCollectibleCount,
                collectiblesLoaded,
                setCollectiblesLoaded,

                // Player Stats
                playerStats,

                // Theme
                chooseTheme,
                setChooseTheme,
                theme,
                changeTheme,

                // Game mode
                mode,
                setGameMode,

                // Settings
                settings,
                setSettings,

                // Audio
                audioPlaying,
                setAudioPlaying,
                musicPlayer,
                setMusicPlayer, 

                // Alert Modal
                alertModal,
                setAlertModal,
                alertMessage,
                setAlertMessage,
                alertHeader,
                setAlertHeader,

                // Socket for Game Server
                setSocket,

                // Demo
                demo,
                playDemo,

                // 0x
                zeroXModal,
                setZeroXModal,

                // Shop Items
                balanceCheck,
                shopItems,
                setShopItems,
                getShopItems,

                // Purchase Modal
                waitModal,
                setWaitModal,

                // Tooltip
                tooltip,
                setTooltip,
                showTooltip,
                setShowTooltip,

                // Testnet
                testnet,

                // Logout
                logout
            }}
        >
        <ColosseumBackdrop />
        {children}

            <IonToast
                position="bottom"
                mode='md'
                color={'primary'}
                isOpen={newVersion}
                message="Game Update! Please refresh your browser."
                buttons={[{
                    text: 'Refresh',
                    handler: () => { window.location.reload() }
                }]}
            />

            <IonAlert
                mode='md'
                color={'primary'}
                isOpen={showNetworkAlert}
                onDidDismiss={() => setShowNetworkAlert(false)}
                header={'Incorrect Network'}
                message={networkMessage}
                buttons={[
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                        setShowNetworkAlert(false)
                        setWaitModal(false)
                    }
                },
                {
                    text: 'Switch Network',
                    handler: () => {

                        setShowNetworkAlert(false)
                        
                        let chainId: string;
                        if (envmode === 'dev') {
                            chainId = `0x${getNetworkId(Network.Sepolia).toString(16)}`;
                        } else {
                            chainId = `0x1`;
                        }
                        
                        provider?.provider.request({
                            method: 'wallet_switchEthereumChain',
                            params: [{ chainId }],
                        }).then(() => {
                            setWaitModal(false)
                            chooseProvider(provider)
                        }).catch((error: any) => {
                            setWaitModal(false)
                        });
                        
                        
                    }
                }
                ]}
            />

            {/* General Alert Modal */}
            <IonAlert
                mode='md'
                color={'primary'}
                isOpen={alertModal}
                onDidDismiss={() => setAlertModal(false)}
                header={alertHeader}
                message={alertMessage}
                buttons={['OK']}
            />

            <WalletInfo />

            {themeChangeOverlay ? (
                <div id="theme-change-overlay" />
            ) : null}

            <WaitModal />

        </UserContext.Provider>
    );
};

export default UserContextProvider;

import { dc } from '../Utils';
import { CollectionDetails, Network } from '../config/collections.interface';
import { demoWallet } from '../config/config';
import User from "../interfaces/User.interface";

/**
 * Get a Wallet's NFTs from Reservior
 * @param user User object
 * @param collection: CollectionDetails
 * @param limit: number
 * @returns Promise<PriceResponse>
 */
export const reserviorGetOwners = async (
    user: User,
    collection: CollectionDetails,
    limit: number = 200,
) => {
    return new Promise<any>(async (resolve, reject) => {

        // No wallet check
        if (!user.wallet) {
            reject('No wallet');
            return;
        }

        // Determine if we should use mainnet or testnet
        let isMainnet = collection.mainnet_address && collection.mainnet_address !== '';
        let isTestnet = !isMainnet && collection.testnet_address && collection.testnet_address !== '';

        // If this is the demo wallet, default to mainnet
        if (user.wallet === demoWallet) {
            isMainnet = true;
            isTestnet = false;
        }
        
        if (!isMainnet && !isTestnet) {
            reject('No valid network address found for the collection');
            return;
        }

        // Determine the network and corresponding API URL
        let apiUrl = '';
        const network = isMainnet ? collection.mainnet_network : collection.testnet_network;

        switch (network) {
            case Network.Mainnet:
                apiUrl = 'https://api.reservoir.tools/users';
                break;
            case Network.Sepolia:
                apiUrl = 'https://api-sepolia.reservoir.tools/users';
                break;
            case Network.Base:
                apiUrl = 'https://api-base.reservoir.tools/users';
                break;
            case Network.BaseSepolia:
                apiUrl = 'https://api-base-sepolia.reservoir.tools/users';
                break;
            default:
                reject('Unsupported network');
                return;
        }

        // Prepare URL query params, ensuring 'collection' is a string
        const params: Record<string, string> = {
            collection: isMainnet ? collection.mainnet_address || '' : collection.testnet_address || '',
            owner: user.wallet,
            limit: limit.toString(),
        };

        // Prepare full API URL with the query params
        const queryParams = new URLSearchParams(params).toString();
        const path = `${apiUrl}/${user.wallet}/tokens/v9?${queryParams}`;

        try {
            const response = await fetch(path, {
                headers: {
                    'x-api-key': `${dc(process.env.REACT_APP_RESERVOIR_API_KEY)}`,
                },
            });

            if (!response.ok) {
                reject(response.statusText);
                return;
            }

            const data = await response.json();
            const tokenIDs = data.tokens.map((token: any) => token.token);
            resolve(tokenIDs);

        } catch (error) {
            reject(error);
        }
    });
}

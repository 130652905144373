// Config
import { LocalCollectionProperties } from "../../config/collections.interface";
import { CollectionSlug, spacesStorage } from "../../config/collections";
import WelcomeBlock from "./WelcomeBlock";

// Collection Slug
const slug: CollectionSlug = CollectionSlug.Bedlams;

// Object Path
export const objectPath_bedlams = `${spacesStorage}/${slug}`;
// const frames = `${objectPath_bedlams}/shop/cardframe`;
// const wearables = `${objectPath_bedlams}/shop/wearable`;
// const backdrops = `${objectPath_bedlams}/backdrops`;

const collection: LocalCollectionProperties = {
    slugLocal: slug,
    partner: true,
    logo: `${objectPath_bedlams}/welcome/logo.png`,
    logoSmall: `${objectPath_bedlams}/welcome/logo-small.png`,
    bgImage: `${objectPath_bedlams}/welcome/bg.png`,
    cardImages: [
        `${objectPath_bedlams}/welcome/card-2.png`, 
        `${objectPath_bedlams}/welcome/card-1.png`, 
        `${objectPath_bedlams}/welcome/card-3.png`
    ],
    gameplay: {
        cardFront: `${objectPath_bedlams}/card/front.png`,
        cardBack: `${objectPath_bedlams}/card/back.png`
    },
    colosseumBackdrop: `${objectPath_bedlams}/backdrops/She_Who_Consumes_Background.mp4`,
    welcomeComponent: <WelcomeBlock />,
};

export default collection;

import { CollectionSlug } from '../config/collections';
import { api, apiKey, envmode } from '../Env';
import { TopPlayers } from '../interfaces/Leaderboard.interface';
import { Stats, defaultStats } from '../interfaces/Token.interface';

import { createClient } from '@supabase/supabase-js'
import { BackgroundRow, CollectionRow, InGameCurrencyRow, PlayerInsert, PlayerRow, ShopItemRow } from './types';
import User from '../interfaces/User.interface';

/**
 * Supabase Client
 */
export const supabase = createClient(api(), apiKey())

export interface SupabseCollectionInterface {
    uuid: string;
    name: string;
    description: string;
    creator: string;
    slug: CollectionSlug;
    mainnet_address: string;
    mainnet_baseuri: string;
    mainnet_extension: string;
}

/**
 * Load Collections from Supabase
 * @returns SupabseCollectionInterface[]
 */
export const loadCollections = async () => {
    return new Promise<CollectionRow[]>((resolve, reject) => {
        supabase.rpc(envmode === 'dev' ? 'get_local_collections' : 'get_enabled_collections').then(res => {
            if (res.data) {
                resolve(res.data as CollectionRow[]);
            }
        });
    })
}

/**
 * Load Backgrounds
 * @returns BackgroundRow[]
 */
export const loadBackgrounds = async () => {
    return new Promise<BackgroundRow[]>(async (resolve, reject) => {
        const { data, error } = await supabase
        .from('backgrounds')
        .select()
        if (error) {
            console.warn('Error fetching in-game currency balance:', error);
            resolve([]);
        } else {
            resolve(data as BackgroundRow[]);
        }
    })
}

/**
 * Get Top Three Players
 * @returns TopPlayers[]
 */
export const getTopPlayers = async () => {
    return new Promise<TopPlayers[]>((resolve, reject) => {
        supabase.rpc('leaderboard').then(res => {
            if (res.data) {
                resolve(res.data as TopPlayers[]);
            }
        });
    })
}

/**
 * Get Shop Items
 * @returns ShopItem[]
 */
export const getItemsForSale = async () => {
    return new Promise<ShopItemRow[]>((resolve, reject) => {
        supabase.from('items').select('*').then(res => {
            if (res.data) {
                // Sort by collection then by name
                res.data.sort((a, b) => a.collection.localeCompare(b.collection) || a.name.localeCompare(b.name));
                resolve(res.data);
            }
        });
    })
}

/**
 * Get Token Stats
 * @param tokenId 
 * @param collection 
 * @returns any
 */
export const getTokenStats = async (tokenId: number, collection: string) => {
    return new Promise<Stats>((resolve, reject) => {
        try {
            supabase.rpc('token_battle_stats', {
                p_collection: collection,
                p_token_id: tokenId
            }).then(res => {
                if (res.data && res.data.length) {
                    resolve(res.data[0]);
                }
            })
        } catch (error) {
            console.warn('Error fetching token stats:', error);
            resolve(defaultStats);
        }
    })
}

/**
 * Get In-Game Currency Balance
 * @param player_uuid 
 * @returns any
 */
export const getInGameCurrencyBalance = async (player_uuid: string) => {
    return new Promise<InGameCurrencyRow | null>(async (resolve, reject) => {
        const { data, error } = await supabase
        .from('in_game_currency')
        .select()
        .eq('player_uuid', player_uuid)
        .single();
        
        if (error) {
            console.warn('Error fetching in-game currency balance:', error);
            resolve(null)
        } else {
            resolve(data);
        }

    })
}



/**
 * Get Purchases for Connected Wallet
 * @param user User object
 * @returns ShopItem[]
 */
export const getPurchases = async (user: User) => {
    return new Promise<ShopItemRow[]>(async (resolve, reject) => {


        // Get the player's purchases from the player_inventory table
        // Then using the game_purchases.item_id, get all game_accessory records relative to that id
        const { data, error } = await supabase
        .from('player_inventory')
        .select(`
            items (
                *
            )
        `)
        .eq('player_uuid', user.player?.uuid)
        .order('id', { ascending: false });

        if (error) {
            console.warn('Error fetching player_inventory:', error);
            resolve([]);
        }

        // console.log('supabase: player_inventory -> getPurchases', data, error);

        const cleanedArray = data?.length ? data?.map(item => item.items) : [];

        // console.log('supabase: game_purchases -> getPurchases', cleanedArray, error);

        resolve(cleanedArray as any[]);


    });
}

/**
 * Update Player Row
 * @param user 
 * @param data 
 * @returns boolean
 */
export const updatePlayerRow = async (user: User, data: PlayerInsert) => {
    return new Promise<boolean>(async (resolve, reject) => {
        const { error } = await supabase
        .from('players')
        .update(data)
        .eq('uuid', user.player?.uuid);

        if (error) {
            console.warn('Error updating player:', error);
            resolve(false);
        } else {
            resolve(true);
        }
    })
}

/**
 * Get the URL of the avatar from the storage bucket
 * @param player PlayerRow
 * @returns URL of the avatar
 */
export async function getAvatarURLfromStorage(player: PlayerRow) {
    const { data, error } = await supabase.storage.from('avatars').list(player?.uuid);

    if (error) {
        console.error('Error getting file:', error);
        return null;
    } else {
        // There is only one avatar per user
        const fileName = data[0].name;
        const modified_date = data[0].created_at;

        // Construct the URL
        const url = supabase.storage.from('avatars').getPublicUrl(fileName);
        const publicURL = url.data.publicUrl;
        // The path includes `../avatars/` so  we need to ensure after is a subfolder of the user_id
        const publicURLwithUser = publicURL.replace('avatars/', `avatars/${player?.uuid}/`);

        // Create a URL object
        const urlObj = new URL(publicURLwithUser);

        // Append the query string
        urlObj.searchParams.append('e', modified_date);

        return urlObj.toString();
    }
}

import { MothAbilities, MuffinAbilities } from "../types/Abilities.type";

export enum AbilityType {
    Attack = 'attack',
    HealDefense = 'heal-defense'
}

export interface MuffinAbilityLib {
    name: MuffinAbilities;
    power: number;
    energy: number;
    group?: boolean;
    teammate?: boolean;
    self?: boolean;
}

export interface MothAbilityLib {
    name: MothAbilities;
    power: number;
    energy: number;
    group?: boolean;
    teammate?: boolean;
    self?: boolean;
}

export interface Ability extends MuffinAbilityLib {
    id: number;
}

export interface MothAbility extends MothAbilityLib {
    id: number;
}

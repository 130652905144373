// 
// THIS FILE HAS BEEN GENERATED AUTOMATICALLY
// DO NOT CHANGE IT MANUALLY UNLESS YOU KNOW WHAT YOU'RE DOING
// 
// GENERATED USING @colyseus/schema 2.0.25
// 

import { Schema, type, ArraySchema, MapSchema, SetSchema, DataChange } from '@colyseus/schema';


export class ShopItem extends Schema {
    @type("number") public id!: number;
    @type("string") public type!: string;
    @type("string") public name!: string;
    @type("string") public description!: string;
    @type("string") public collection!: string;
    @type("number") public cost_usdc!: number;
    @type("number") public cost_eth!: number;
    @type("number") public cost_gladii!: number;
    @type("string") public metadata!: string;
}

// Config
import { CollectionDetails } from "../../config/collections.interface";

// React
import { useContext, useEffect, useState } from "react";

// Interfaces
import { Equipment, EquipmentType } from "../../interfaces/Equipment.interface";

// Colyseus
import { TokenMetadata } from "../../generated/TokenMetadata";
import { UserContext } from "../../Context";

interface PlayerCardProps {
    card: TokenMetadata;
    mode: EquipmentType.FRAMES | EquipmentType.WEARABLES;
    side: 'front' | 'back'
}

const PlayerCardEquipment: React.FC<PlayerCardProps> = ({ card, mode, side }) => {

    const { collections } = useContext(UserContext);
    const [collection, setCollection] = useState<CollectionDetails | undefined>();
    const [cardFront, setCardFront] = useState<string>();
    const [cardBack, setCardBack] = useState<string>();
    const [cardWearableFront, setCardWearableFront] = useState<string>();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [cardWearableBack, setCardWearableBack] = useState<string>();
    const [shopItemAsset, setShopItemAsset] = useState<Equipment>();

    useEffect(() => {
        if (collections.length) {
            setCollection(collections.find(c => c.slug === card.collection));
        }
    }, [collections, card.collection])

    useEffect(() => {

        // Loop through the collection equipment and set the matching shop item asset
        collection?.equipment?.forEach((item) => {
            if ((mode === EquipmentType.FRAMES) && (item.id === card.frame?.id)) {
                setShopItemAsset(item);
            }
            if ((mode === EquipmentType.WEARABLES) && (item.id === card.wearable?.id)) {
                setShopItemAsset(item);
            }
            if (((mode === EquipmentType.FRAMES) && (!card.frame?.id)) || ((mode === EquipmentType.WEARABLES) && (!card.wearable?.id))) {
                setShopItemAsset(undefined);
            }
        });

    }, [collection, mode, card.id, card.frame?.id, card.wearable?.id])

    useEffect(() => {

        switch (shopItemAsset?.type) {

            case EquipmentType.FRAMES:

                // Set selected card frame
                setCardBack(shopItemAsset?.image_back);
                setCardFront(shopItemAsset?.image_front);

                break;

            case EquipmentType.WEARABLES:

                // Set default card frame
                setCardBack(collection?.gameplay?.cardBack);
                setCardFront(collection?.gameplay?.cardFront);

                // Set wearable images
                setCardWearableFront(shopItemAsset?.image_front);
                setCardWearableBack(shopItemAsset?.image_back);

                break;
                
            default:

                switch (mode) {
                    case EquipmentType.FRAMES:

                        // Reset card frame
                        setCardFront(collection?.gameplay?.cardFront);
                        setCardBack(collection?.gameplay?.cardBack);

                        break;
                    case EquipmentType.WEARABLES:

                        // Reset wearable images
                        setCardWearableFront(undefined);
                        setCardWearableBack(undefined);

                        break;
                }

                break;

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shopItemAsset, collection, card.frame?.id, card.wearable?.id])

    return (
        <>
        {(mode === EquipmentType.WEARABLES) && (side === 'back') && shopItemAsset ? 
            <img className="wearable-front" src={cardWearableFront} loading="lazy" alt="" /> 
        : null}
        {(mode === EquipmentType.WEARABLES) && (side === 'front') && shopItemAsset ? 
            <img className="wearable-back" src={cardWearableFront} loading="lazy" alt="" /> 
        : null}
        {(mode === EquipmentType.FRAMES) && (side === 'front') ? 
            <img className="frame-front" src={cardFront} loading="lazy" alt="" /> 
        : null}
        {(mode === EquipmentType.FRAMES) && (side === 'back') ? 
            <img className="frame-back" src={cardBack} loading="lazy" alt="" /> 
        : null}
        </>
    );
}

export default PlayerCardEquipment;
// Colyseus
import { Ability } from '../../generated/Ability'

// Styles
import './AbilityIcon.scss'

// Cosmic Muffins
import { ReactComponent as MuffinMelee } from '../../collections/cosmicmuffins/abilities/cosmicmuffins_muffin-melee.svg';
import { ReactComponent as ButterBlast } from '../../collections/cosmicmuffins/abilities/cosmicmuffins_butter-blast.svg';
import { ReactComponent as CosmicLaser } from '../../collections/cosmicmuffins/abilities/cosmicmuffins_cosmic-laser.svg';
import { ReactComponent as CrumbleCure } from '../../collections/cosmicmuffins/abilities/cosmicmuffins_crumble-cure.svg';
import { ReactComponent as Electronaut } from '../../collections/cosmicmuffins/abilities/cosmicmuffins_electronaut.svg';

// Sacred Moths
import { ReactComponent as GalacticDustBeam } from '../../collections/sacredmoths/abilities/sacredmoths_galactic-dust-beam.svg';
import { ReactComponent as HyperdriveFlare } from '../../collections/sacredmoths/abilities/sacredmoths_hyperdrive-flare.svg';
import { ReactComponent as NebulaPulse } from '../../collections/sacredmoths/abilities/sacredmoths_nebula-pulse.svg';
import { ReactComponent as StardustRevive } from '../../collections/sacredmoths/abilities/sacredmoths_stardust-regeneration.svg';

// Buns
import { ReactComponent as BushidoBun } from '../../collections/buns/abilities/buns_bushido-bun.svg';
import { ReactComponent as CarrotGuard } from '../../collections/buns/abilities/buns_carrot-guard.svg';
import { ReactComponent as HatTrick } from '../../collections/buns/abilities/buns_hat-trick.svg';
import { ReactComponent as ThumberBomb } from '../../collections/buns/abilities/buns_thumper-bomb.svg';


interface Props {
    ability: Ability;
}

const AbilityIcon: React.FC<Props> = ({ ability }) => {
  return (
    <span className='ability-icon' data-ability={ability.name}>
    {/* Cosmic Muffins */}
    {ability.name === 'Muffin Melee' && (<MuffinMelee />)}
    {ability.name === 'Butter Blast' && (<ButterBlast />)}
    {ability.name === 'Cosmic Laser' && (<CosmicLaser />)}
    {ability.name === 'Crumble Cure' && (<CrumbleCure />)}
    {ability.name === 'Electronaut' && (<Electronaut />)}

    {/* Sacred Moths */}
    {ability.name === 'Galactic Dust Beam' && (<GalacticDustBeam />)}
    {ability.name === 'Hyperdrive Flare' && (<HyperdriveFlare />)}
    {ability.name === 'Nebula Pulse' && (<NebulaPulse />)}
    {ability.name === 'Stardust Revive' && (<StardustRevive />)}

    {/* Buns */}
    {ability.name === 'Bushido Bun' && (<BushidoBun />)}
    {ability.name === 'Carrot Guard' && (<CarrotGuard />)}
    {ability.name === 'Hat Trick' && (<HatTrick />)}
    {ability.name === 'Thumper Bomb' && (<ThumberBomb />)}
    </span>
  )
}

export default AbilityIcon
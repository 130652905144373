// Config
import { CollectionSlug } from '../../config/collections';

// React
import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../Context';
import { PlayContext, PlayState } from '../../pages/PlayContext';

// Interfaces
import { UserState } from '../../interfaces/User.interface';
import { CollectionDetails } from "../../config/collections.interface";

// Silhouettes
import darkMuffin from '../../images/shop/dark-muffin.png';
import darkMoth from '../../images/shop/dark-moth.png';

// Utils
import classNames from 'classnames';

// Colyseus
import { Equipment, EquipmentType } from '../../interfaces/Equipment.interface';
import { ShopItemRow } from '../../supabase/types';

interface Props {
    shopItem: ShopItemRow;
    cardFlip?: boolean;
    cardPreview?: boolean;
    onClick?: () => void;
}

const CardEquipment: React.FC<Props> = ({ shopItem, cardFlip = true, cardPreview = true, onClick }) => {

    const { router, user, shopItems, collections } = useContext<UserState>(UserContext);
    const { 
        prepareToken,
        frame, 
        setFrame,
        wearable,
        setWearable,
        isEquipmentAttachedToAnyTeamMember,
        isThisEquipmentAttachedToThisToken
    }: PlayState = useContext(PlayContext);

    const [owned, setOwned] = useState<boolean>(false);
    const [cardFlipped, setCardFlipped] = useState<boolean>(true);  
    const [display, setDisplay] = useState<'display' | 'get-ready'>('display');

    const [collection, setCollection] = useState<CollectionDetails>();
    const [backgroundImage, setBackgroundImage] = useState<string>();
    const [cardFront, setCardFront] = useState<string>();
    const [cardBack, setCardBack] = useState<string>();
    const [cardWearableFront, setCardWearableFront] = useState<string>();
    // const [cardWearableBack, setCardWearableBack] = useState<string>();
    const [shopItemAsset, setShopItemAsset] = useState<Equipment>();



    useEffect(() => {

        // Set the collection
        collections.forEach((c) => {
            if (shopItem.collection === c.uuid) {
                setCollection(c);
            }
        });

        // Change display type based on screen
        if (router?.routeInfo.pathname === '/play/battle') {
            setDisplay('get-ready');
        } else {
            setDisplay('display');
        }

    }, [collections, shopItem, shopItem?.id, router?.routeInfo.pathname, wearable])

    useEffect(() => {

        switch(collection?.slug) {
            case CollectionSlug.CosmicMuffins:
                setBackgroundImage(darkMuffin);
                break;
            case CollectionSlug.SacredMoths:
                setBackgroundImage(darkMoth);
                break;
        }

        // Based on the ShopItem ID, set the wearable image
        collection?.equipment?.forEach((item) => {
            if (item.id === shopItem.id) {
                setShopItemAsset(item);
            }
        });

    }, [collection, shopItem?.id])

    useEffect(() => {
        if (shopItemAsset) {

            switch(shopItemAsset?.type) {

                case EquipmentType.FRAMES:

                    // Set shop item card frame
                    setCardBack(shopItemAsset?.image_back);
                    setCardFront(shopItemAsset?.image_front);

                    break;

                case EquipmentType.WEARABLES:

                    // Set default card frame
                    setCardBack(collection?.gameplay?.cardBack);
                    setCardFront(collection?.gameplay?.cardFront);

                    // Set wearable images
                    setCardWearableFront(shopItemAsset?.image_front);
                    // setCardWearableBack(shopItemAsset?.image_back);

                    break;
            }
        }
    }, [shopItemAsset, collection, shopItem?.id])

    useEffect(() => {
        if (shopItems) {
            const ownedItem = shopItems.find(item => item.id === shopItem.id);
            if (ownedItem) {
                setOwned(true);
            }
        }
    }, [shopItems, user.chainID, shopItem]);

    const cardFrameClasses = classNames(
        'player-card',
        'shop-item',
        'gladiator-arena',
        'flip-card',
        `owned-${owned}`,
        cardFlipped ? 'flipped' : 'not-flipped',
        'pointer',
        wearable?.id === shopItem.id ? 'selected' : ''
    );

    return (
        <>
        <div 
          id={`card_${shopItem.id}`}
          className={cardFrameClasses} 
          onClick={() => { 

            switch (display) {
                case 'display':
                    if (cardFlip) {
                        setCardFlipped(!cardFlipped)
                    }
                    break;
                case 'get-ready':

                    console.log('prepareToken', prepareToken);

                    /**
                     * Toggle context wearable state
                     */
                    const toggleItem = () => {

                        switch (shopItemAsset?.type) {
                            case EquipmentType.FRAMES:
                                if (frame) {
                                    setFrame(null);
                                } else {
                                    setFrame(shopItem);
                                }
                                break;
                            case EquipmentType.WEARABLES:
                                if (wearable) {
                                    setWearable(null);
                                } else {
                                    setWearable(shopItem);
                                }
                                break;
                        }
                    }

                    /**
                     * If the user has a token selected, and the shop item is attached
                     * allow them to toggle it on and off
                     */
                    if (prepareToken && isThisEquipmentAttachedToThisToken(prepareToken, shopItem)) {
                        toggleItem();
                    }

                    /**
                     * If the user has a token selected, and the shop item is not attached
                     * allow them to attach it
                     */
                    if (prepareToken && !isEquipmentAttachedToAnyTeamMember(shopItem)) {
                        toggleItem();
                    }

                    break;
            }

            if (onClick) {
                onClick();
            }

          }}
        >
            <div className={`aspect-ratio aspect-ratio--5x7`}>
                <div className="aspect-ratio--object">
                    <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <div className='front-card-container'>
                            <div className='card-contents animate__animated animate__zoomIn'>
                                <div className="nft" data-tilt data-tilt-glare data-tilt-max-glare="0.4" data-tilt-max="6">
                                    <div className="art-container-bg" />  
                                    <div 
                                        className="art-container"
                                        style={{ backgroundImage: `url(${ backgroundImage })` }}
                                    />  
                                    <div className="card-text-behind-frame">
                                    </div>
    
                                    <div className={`card-frame ${shopItemAsset?.type === EquipmentType.WEARABLES ? `dark` : ``}`}>
                                        <img src={cardFront} alt="" />
                                    </div>
    
                                    <div className="card-text">
                                        {display !== 'get-ready' ? (
                                            <div className="name gladiator-arena">{shopItem.name}</div>
                                        ) : null}
                                        <div className="owned-overlay gladiator-arena">
                                            {owned && display !== 'get-ready' ? (
                                            <>
                                                Purchased
                                            </>
                                            ) : (
                                            <>
                                            <span className="f5 w4">
                                            {shopItem.name}
                                            </span>
                                            </>
                                            )}
                                        </div>                                  
                                    </div>

                                    {cardWearableFront ? (
                                        <div className="wearable-image">
                                            <img src={cardWearableFront} alt="" />
                                        </div>
                                    ) : null}

                                </div>
                            </div>                      
                            </div>
                        </div>
                        <div className="flip-card-back">
                            <div className="back-card-container pointer"> 
                                <div className='card-contents animate__animated animate__zoomIn'>
                                <div className="nft" data-tilt data-tilt-glare data-tilt-max-glare="0.4" data-tilt-max="6">
                                    <div className={`card-frame ${shopItemAsset?.type === EquipmentType.WEARABLES ? `dark` : ``}`}>
                                        <img src={cardBack} alt="" />
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>  
        </>
    );

}

export default CardEquipment;

// Config
import { getNetworkId } from './utils'
import { Network } from './collections.interface'

// Images
import ethIcon from './../images/0x/eth.png'
import link from './../images/0x/link.png'

// Contracts
export const USDC = '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48'
export const GladiatorReceiverSepolia = '0x6ffBe5FbAcd16CF99DEec29Fb44057770Fbd9302'
export const GladiatorReceiverMainnet = '0x3AB6d76E56FD27A32A2dEc87820681AA40395f4C'
export const ETHAddress = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'
export const LINKSepolia = '0x779877A7B0D9E8603169DdbD7836e478b4624789'

// Object for ETH related operations
export const ETH = {
    address: ETHAddress,
    chainId: 1,
    logoURI: ethIcon,
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18
}

// Test net object (yeah yeah I know this can be cleaner)
export const TestTokenObject = {
    "name": "Test Tokens",
    "logoURI": "",
    "keywords": [
        "defi"
    ],
    "timestamp": "",
    "tokens": [
        {
            "chainId": getNetworkId(Network.Sepolia),
            "address": ETHAddress,
            "name": 'Ether',
            "symbol": 'ETH',
            "decimals": 18,
            "logoURI": ethIcon
        },
        {
            "chainId": getNetworkId(Network.Sepolia),
            "address": LINKSepolia,
            "name": "Link",
            "symbol": "LINK",
            "decimals": 18,
            "logoURI": link
        },
        {
            "chainId": 1,
            "address": USDC,
            "name": "USDC",
            "symbol": "USDC",
            "decimals": 6,
            "logoURI": "https://assets.coingecko.com/coins/images/6319/thumb/usdc.png?1696506694"
        }
    ],
    "version": {
        "major": 217,
        "minor": 1,
        "patch": 0
    }
}
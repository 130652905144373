// React
import { UserContext } from '../Context';
import { useContext, useEffect } from 'react';

// Ionic
import { IonButton, IonContent, IonIcon, IonPage, IonSpinner, useIonAlert } from '@ionic/react';

// Colyseus
import { RoomAvailable } from 'colyseus.js';

// Interfaces
import { UserState } from '../interfaces/User.interface';
import { PlayContext, PlayState } from './PlayContext';

// Services
import { createRoom, join, leave } from '../services/GameServer';

// Styles
import './Lobby.scss';

// Images
import swordsIcon from './../images/swords.svg';

// Utils
import { shortenWalletAddress } from '../Utils';

const Lobby: React.FC = () => {

  const { user } = useContext<UserState>(UserContext);
  const { lobby, room, rooms, roomCreated, setRoom }: PlayState = useContext(PlayContext);
  const [presentAlert] = useIonAlert();

  useEffect(() => {
    // console.log('rooms', rooms)
    // console.log('roomCreated', roomCreated)
  }, [rooms, roomCreated, user.collectibles.length])

  /**
   * Replace underscores with spaces
   * @param room RoomAvailable object
   * @returns string
   */
  const roomName = (room: RoomAvailable) => {
    return room.metadata.roomName.replaceAll('_', ' ');
  };

  /**
   * Shorten wallet address
   * @param room RoomAvailable object
   * @returns string
   */
  const roomCreatedBy = (room: RoomAvailable) => {
    return shortenWalletAddress(room.metadata.wallet, 6, 6)
  }

  /**
   * 
   * @param room 
   * @returns 
   */
  const roomStatus = (room: RoomAvailable): JSX.Element => {
    const icon: JSX.Element = room.clients === 2 ? <IonIcon src={swordsIcon} /> : <IonSpinner name="dots" />;
    return <div className='flex items-center'>{icon}<span className="dib ml2 pt2 gladiator-arena">{room?.metadata?.status}</span></div>
  }

  /**
   * Join or leave room button
   * @param room RoomAvailable object
   * @returns IonButton
   */
  const roomButton = (roomAvailable: RoomAvailable): JSX.Element => {

    // If I have created the room and I am in it, show leave button
    if (roomAvailable.metadata.wallet === user.wallet && room) {
        return <IonButton 
                    color={`transparent`}
                    className='ml3'
                    onClick={() => {
                        if (lobby && room){
                            leave(user, room).then(() => {
                                setRoom(null)
                                lobby.send('room_left');
                            });
                        }
                    }}
                ><span className="dib pt2 gladiator-arena">Leave</span></IonButton>
    } else {
        /**
         * Disable join button if the room is full or if the user already created a room
         */

        let tokensOwned = user.collectibles.length;

        return <IonButton 
                    color={`transparent`}
                    className='ml3'
                    disabled={roomCreated || roomAvailable.clients === 2 || tokensOwned === 0}
                    onClick={() => {
                        if (user.wallet && lobby) {
                            join(user, roomAvailable).then(room => {
                                setRoom(room)
                                lobby.send('room_joined');
                            }).catch((e) => {
                                presentAlert({
                                    header: 'Error',
                                    message: `${e}`,
                                    buttons: ['OK'],
                                  })
                            });
                        }
                    }}
                ><span className="dib pt2 gladiator-arena">Join</span></IonButton>
    }
  }

  /**
   * Highlight the room if the user created it
   * @param room RoomAvailable object
   * @returns string
   */
  const roomClass = (room: RoomAvailable): string => {
    return room.metadata.wallet === user.wallet ? 'created' : ''
  }

  const PlaceholderRow = () => (
    <div className="colyseus-room placeholder-row flex w-100 ba b--white-20 mb3">
      <div className="table-cell o-50">&ndash;</div>
      <div className="table-cell o-50">&ndash;</div>
      <div className="table-cell o-50">&ndash;</div>
      <div className="table-cell o-50">&ndash;</div>
    </div>
  );

  return (
    <IonPage className='ion-page-lobby z-2'>
        <IonContent fullscreen className='transparent'>
            <div className="gladiator-lobby-container">
                <div className={`w-100`}>
                    <div className="relative row">
                        <div className="column small-4 tl">
                            &nbsp;
                        </div>
                        <div className="column small-4 tc">
                            <h2 className='gladiator-arena ml3'>Lobby</h2>
                        </div>
                        <div className="column small-4 tr">
                            <IonButton 
                                color={`primary`}
                                className='ml3'
                                onClick={() => {
                                    if (user.wallet && lobby) {
                                        createRoom(user, `battle`).then((room) => {
                                            setRoom(room);
                                            lobby.send('room_created');
                                        })
                                    }
                                }}
                                disabled={roomCreated || (!user.collectibles.length)}
                            ><span className="dib pt2 gladiator-arena">Create</span></IonButton>
                        </div>
                    </div>
                    <div className="relative row lobby-content z-1 animate__animated animate__zoomIn">
                        <div className="column small-12">
                            <div className="grid-x grid-padding-x">
                                <div className="small-12">
                                    <div className="sticky-header flex w-100">
                                        <div className="table-header"><span className="dib pt2 gladiator-arena">Session Name</span></div>
                                        <div className="table-header"><span className="dib pt2 gladiator-arena">Players</span></div>
                                        <div className="table-header"><span className="dib pt2 gladiator-arena">Status</span></div>
                                        <div className="table-header"><span className="dib pt2 gladiator-arena">Actions</span></div>
                                    </div>
                                    {rooms.map((room, i: number) => {
                                        return (
                                            <div key={`colyseus_room_${i}`} className={`colyseus-room ${roomClass(room)} flex w-100 ba b--white-20 mb3`}>
                                                <div className="table-cell ttc">
                                                    <div className="flex flex-column">
                                                        <div>
                                                            <span className="dib pt2 gladiator-arena">{roomName(room)}</span>
                                                        </div>
                                                        <div className='f6 mt0 i'>Created by: {roomCreatedBy(room)}</div>
                                                    </div>
                                                </div>
                                                <div className="table-cell"><span className="dib pt2 gladiator-arena f4">{room.clients}</span></div>
                                                <div className="table-cell">{roomStatus(room)}</div>
                                                <div className="table-cell">{roomButton(room)}</div>
                                            </div>
                                        )
                                    })}
                                    {[...Array(10 - rooms.length)].map((_, i) => <PlaceholderRow key={`placeholder_${i}`} />)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </IonContent>
    </IonPage>
  );
};

export default Lobby;

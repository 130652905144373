// Hooks
import { useContext, useEffect } from "react";
import useMousePosition from "../../hooks/useMousePosition";
import { UserState } from "../../interfaces/User.interface";

// Styles
import './GladiatorCursor.scss'
import { UserContext } from "../../Context";
import { PlayContext, PlayState } from "../../pages/PlayContext";
import { AbilityType } from "../../interfaces/Ability.interface";

const GladiatorCursor = () => {

  const { tooltip, showTooltip }: UserState = useContext(UserContext);
  const { myTurn, myEnergy, abilitySelected }: PlayState = useContext(PlayContext);
  const { x, y } = useMousePosition();

  /**
   * Calculate the power of the ability
   * @returns Power of the ability
   */
  // const calcAbilityPower = (mode: 'total' | 'original'): number => {
  //   let power = tooltip.data?.power || 0;
  //   if (mode === 'original') {
  //     // if (tooltip.collectible?.frame?.metadata?.attack_boost) {
  //     //   power -= +tooltip.collectible.frame.metadata.attack_boost;
  //     // }
  //     // if (tooltip.collectible?.wearable?.attack_boost) {
  //     //   power -= +tooltip.collectible.wearable.attack_boost;
  //     // }
  //     return power;
  //   } else {
  //     return power;
  //   }
  // };
  
  useEffect(() => {
    // console.log(tooltip, showTooltip)
  }, [tooltip, showTooltip, myEnergy, abilitySelected])

  return (
    <>
    {(myTurn) && (
      <div id="game-tooltip" className={`dot ${showTooltip ? 'appear' : ''}`} style={{ left: `${x}px`, top: `${y}px` }}>
          {tooltip?.type === 'ability' && (
          <div className="pa3 tooltip">
            <div className="flex justify-between">
              <p className="mv0 b mb2 underline">Ability:</p>
              {(tooltip?.data?.energy) && (myEnergy && myEnergy >= tooltip?.data?.energy) ? (
                <>
                {abilitySelected?.id === tooltip.data.id ? (
                  <p className="mv0 b mb2 green">Selected and ready!</p>
                ) : (
                  <p className="mv0 b mb2 green">Ready to use!</p>
                )}
                </>
              ) : null}
              {(tooltip?.data?.energy) && ((myEnergy && myEnergy < tooltip?.data?.energy) || (!myEnergy)) ? (
                <p className="mv0 b mb2 red">Need more energy</p>
              ) : null}
            </div>
            <h3 className="gladiator-arena mb1">{tooltip.data?.name}</h3>
            <div className="flex justify-between mb3">
              <p className="mv0 b mb2 underline">Requirements:</p>
              <p className="mv0 b mb2">{tooltip?.data?.energy} &times; energy</p>
            </div>
            <div className="flex justify-between mb3">
              <p className="mv0 b mb2 underline">Effects:</p>
              <p className="mv0 b mb2 flex flex-column">
                {tooltip?.data?.type === AbilityType.HealDefense && <span className="green">{`+${tooltip.data.power} defense`}</span>}
                {tooltip?.data?.type === AbilityType.Attack && <span className="red">{`${tooltip.data.power} damage`}</span>}
              </p>
            </div>

            {tooltip?.collectible?.wearable?.id || tooltip?.collectible?.frame?.id ? (
              <div className="flex justify-between mb3">
                <p className="mv0 b mb2 underline">Wearables:</p>
                <p className="mv0 b mb2 flex flex-column">
                  {tooltip?.collectible?.wearable?.id && <span className="white">{tooltip?.collectible?.wearable?.name}</span>}
                  {tooltip?.collectible?.frame?.id && <span className="white">{tooltip?.collectible?.frame?.name}</span>}
                </p>
              </div>              
            ) : null}

            <div className="flex justify-between mb3">
              <p className="mv0 b mb2 underline">Group:</p>
              <p className="mv0 b mb2">{tooltip?.data?.group ? `Yes` : `No` }</p>
            </div>

            <p className="mt0 mb2">
              <span className="underline b mr2">How to use:</span>
              {tooltip?.data?.type === AbilityType.HealDefense && (
                <span className="mt1 mb3 lh-copy">{'Click on this ability, then click one of your cards to raise its defense value.'}</span>
              )}
              {tooltip?.data?.type === AbilityType.Attack && (
                <span className="mt1 mb3 lh-copy">{`Click on this ability, then click one of your opponent's cards to attack ${tooltip.data.group ? `the group` : `it`}.`}</span>
              )}
            </p>            
          </div>
          )}
      </div>
    )}
    </>
  );
};

export default GladiatorCursor;
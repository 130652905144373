import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Particles, initParticlesEngine } from '@tsparticles/react';
import { Container, MoveDirection, OutMode } from '@tsparticles/engine';
import { loadBasic } from "@tsparticles/basic";
import { UserContext } from '../../Context';
import { UserState } from '../../interfaces/User.interface';

interface ParticleOverlayProps {
    particleCount?: number;
    particleSpeed?: number;
  }

const ParticleOverlay: React.FC<ParticleOverlayProps> = React.memo(({ particleCount = 1200, particleSpeed = 1 }) => {

    const { router } = useContext<UserState>(UserContext);
    const [init, setInit] = useState(false);
    const [showParticles, setShowParticles] = useState(false);

    useEffect(() => {
        initParticlesEngine(async (engine) => {
            await loadBasic(engine);
        }).then(() => {
            setInit(true);
        }).catch((e) => {
            console.error(e);
        });
    }, []);

    useEffect(() => {
        if (router?.routeInfo.pathname === '/' || router?.routeInfo.pathname === '/play/battle') {
            setTimeout(() => {
                setShowParticles(true);
            }, 1000);
        } else {
            setShowParticles(false);
        }
    }, [router?.routeInfo.pathname]);

    const particlesLoaded = async (container?: Container | undefined) => {
        // Handle particles loaded event
    };

    const particleOptions = useMemo(() => ({
        fpsLimit: 120,
        particles: {
            twinkle: {
                particles: {
                    enable: true,
                    frequency: 0.1,
                    opacity: 1,
                },
            },
            color: {
                value: "#ffffff",
            },
            move: {
                direction: MoveDirection.topRight,
                enable: true,
                outModes: {
                    default: OutMode.out,
                },
                random: true,
                speed: particleSpeed,
                straight: false,
            },
            number: {
                density: {
                    enable: true,
                },
                value: particleCount,
            },
            opacity: {
                value: 0.5,
            },
            shape: {
                type: "circle",
            },
            size: {
                value: { min: 0.1, max: 1 },
            },
        },
        detectRetina: true,
    }), [particleCount, particleSpeed]);

    return (
        <div id='tsparticles-container' style={{ position: 'absolute', width: '100%', height: '100%' }}>
            {init ? (
                <Particles
                    id="tsparticles"
                    className={`${showParticles ? `animate__animated animate__fadeIn` : `o-0`}`}
                    particlesLoaded={particlesLoaded}
                    options={particleOptions}
                    style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        top: 0,
                        left: 0,
                        zIndex: 2,
                    }}
                />
            ) : null}
        </div>
    );
});

export default ParticleOverlay;

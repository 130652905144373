// React
import { useContext, useEffect } from 'react';
import { UserContext } from '../Context';
import { PlayContext, PlayState } from './PlayContext';

// Ionic
import { Storage } from '@ionic/storage';
import { IonButton, IonContent, IonPage } from '@ionic/react';

// Services
import { endTurn, leave, rematch } from '../services/GameServer';

// Components
import Backdrop from '../components/play/Backdrop';
import PlayerCard from '../components/play/PlayerCard';
import DigitalClock from '../components/play/DigitalClock';
import Energy from '../components/play/Energy';
import TurnCount from '../components/play/TurnCount';
import AbilityIcon from '../components/play/AbilityIcon';
import GladiatorCursor from '../components/play/GladiatorCursor';
import Power from '../components/play/Power';

// Interfaces
import { UserState } from '../interfaces/User.interface';

// Images
import turnIndicator from '../images/turn-indicator-assets/turn-indicator.png';
import turnIndicatorDial from '../images/turn-indicator-assets/turn-indicator-dial.png';

// Styles
import './Play.scss';

// Utils
import { tilt } from '../Utils';
import GameSummaryDisplay from '../components/play/GameSummary';

// Prepare local storage
const storage = new Storage();
storage.create();

const Play: React.FC = () => {

  // document.documentElement.style.cursor = 'none';
  document.documentElement.classList.add('play');
  

  const { router, user }: UserState = useContext(UserContext);
  const { 

    // State
    room,
    battleRoomState,
    gameStart,
    player,
    myCards,
    myAbilities,
    myEnergy,
    opponent,
    opponentAbilities,
    displayYourTurn,
    displayEvaded,
    cardAbilityPlayed,
    displayAbilityPlayed,
    displayLose,
    displayWin,
    displayTie,
    opponentCardHover,
    opponentCards,
    opponentEnergy,
    randomBackdrop,
    myTurn,
    timerValue,
    turnCount,
    turnMax,
    gameOver,
    gameSummary,

    // Methods
    resetGame,
    setAwaitingRematchResponse

  }: PlayState = useContext(PlayContext);



  /**
   * Opponent Card Click Event
   * @param card Card Clicked
   * @param flipped If the card is already flipped
   */
  // const selfClick = (card: TokenMetadata) => {
  //   if (user.socket && myCard && abilitySelected && abilitySelected.self && room) {
  //     let target: TokenMetadata | TokenMetadata[];
  //     if (abilitySelected.group) {
  //       target = myCards;
  //     } else {
  //       target = card;
  //     }
  //     // ColosseumService.roomEvent(user, room, 'cardAbility', target, myCard, abilitySelected)
  //   }
  // };

  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
    // console.log('room', room)
    // console.log('battleRoomState', battleRoomState)
    // console.log('currentTurn', battleRoomState?.current_turn)
    // console.log('gameStart', gameStart)
    // console.log('myCards', myCards)
    // console.log('opponentCards', opponentCards)
    // console.log('myTurn', myTurn)

    if (gameStart) {
      tilt('tilt-js-my-cards');
    }

  }, [battleRoomState, gameStart, myCards, opponentCards, gameOver, myTurn, myAbilities, opponentAbilities])

  return (
    <>
    <GladiatorCursor />
    <IonPage className='gladiator-page play z-2'>

      {/* Multi Player Brawler */}
      {/* {user.mode && user?.mode.value === 'MB' && otherPlayerConnected ? ( */}
        <>

        {/* Battle Stage */}
        <IonContent fullscreen className='relative z-3'>

          {gameStart !== null && randomBackdrop ? (
            <Backdrop type={randomBackdrop} />
          ): null}

          <div className="player-elements">

            {gameStart !== null ? (
              <>
              <div id="hud-elements" className='animate__animated animate__fadeIn'>
                <div className="power-circles opponent flex-column">
                  <h2 className="power-label gladiator-arena f5 mb0">Power</h2>
                  <Power tokens={opponentCards} />
                </div>
                <div className="energy-meter opponent">
                  <div className="tc energy-label">
                    <h2 className="gladiator-arena f5 mb1 mt0">Energy</h2>
                    <p className="gladiator-arena mt0 f3 mb0">{opponentEnergy ? opponentEnergy : 0}/10</p>
                  </div>
                  <Energy energyValue={opponentEnergy} />
                </div>
                <div className="energy-meter player">
                  <Energy energyValue={myEnergy} />
                  <div className="tc energy-label">
                    <h2 className="gladiator-arena f5 mb1 mt1">Energy</h2>
                    <p className="gladiator-arena mt0 f3 mb0">{myEnergy ? myEnergy : 0}/10</p>
                  </div>
                </div>
                <div className="power-circles player flex-column">
                  <Power tokens={myCards} />
                  <h2 className="power-label gladiator-arena f5 mb0 z-1">Power</h2>
                </div>
              </div>

              {!gameOver ? (
                <>
                  <div className="abilities-played">
                    <div className="opponent-abilities">
                      {opponentAbilities?.map((ability, i: number) => {
                        return (
                          <div key={`opponent_ability_${ability.name}_${i}`} className="ability-icon-container">
                            <div className="ability animate__animated animate__bounceIn">
                              <AbilityIcon ability={ability} />
                              {/* <p className="label gladiator-arena">{ability.name}</p> */}
                            </div>
                          </div>
                        )
                      })}
                    </div>
                    <div className="my-abilities">
                      {myAbilities?.map((ability, i: number) => {
                        return (
                          <div key={`my_ability_${ability.name}_${i}`} className="ability-icon-container">
                            <div className="ability animate__animated animate__bounceIn">
                              <AbilityIcon ability={ability} />
                              {/* <p className="label gladiator-arena">{ability.name}</p> */}
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  <button 
                    className={`turn-indicator ${myTurn ? `your-turn pointer` : ``}`}
                    onClick={(e) => { if (room) { endTurn(room); } }}
                    disabled={!myTurn}
                  >
                    <img className='indicator' src={turnIndicator} alt="" />
                    <img className='dial' src={turnIndicatorDial} alt="" />
                  </button>
                  <div className="turn-clock tc">
                    <div className="tc mt3 turn-timer-label">
                      <p className="gladiator-arena f5 mv0">Turn Timer</p>
                    </div>
                    {(myTurn !== null) ? (
                      <DigitalClock myTurn={myTurn} timerValue={timerValue} />
                      ) : null}
                      <TurnCount turnCount={turnCount} turnMax={turnMax} />
                      <IonButton 
                        className={`end-turn-button ${myTurn && myEnergy === 0 ? `pulse` : ``}`}
                        disabled={!myTurn}
                        onClick={(e) => { if (room) { endTurn(room); } }} color={'transparent'}
                      >
                        <span className="dib pt2 gladiator-arena">
                          {myTurn ? 'Done' : 'Wait'}
                        </span>
                      </IonButton>
                  </div>
                </>
              ) : null}
              </>
            ) : null}

            {/* Opponent Cards */}
            <div className={`player-cards top card-count-${opponentCards ? opponentCards.length : 0}`}>
            {opponentCards?.map((card, i: number) =>  (
              <PlayerCard 
                key={`player_card_${card.token_id}_${card.collection}_${i}`} 
                display='opponent'
                card={card}
                hovered={ (opponentCardHover && opponentCardHover.token_id === card.token_id) ? true : false }
              />
            ))}
            </div>

            {gameStart !== null ? (
              <>
              <div id="center-stage" className='animate__animated animate__fadeIn'>
                <div className="ability-played-container">
                  <div className={`ability-played animate__animated ${displayAbilityPlayed ? `animate__bounceInLeft` : `animate__bounceOutRight`}`}>
                    <h1 className='gladiator-arena mv0 pv0'>{cardAbilityPlayed?.name}</h1>
                  </div>
                </div>
                <div className="your-turn-container">
                  <div className={`your-turn animate__animated ${displayYourTurn ? `animate__zoomIn` : `animate__zoomOut`}`}>
                    {myTurn && <h1 className='gladiator-arena mv0 pv0'>Your Turn!</h1>}
                  </div>
                </div>
                <div className="evaded-container">
                  {displayEvaded ? (
                    <div className={`evaded animate__animated ${displayEvaded ? `animate__zoomIn` : `animate__zoomOut`}`}>
                      {<h1 className='gladiator-arena mv0 pv0'>Evaded!</h1>}
                    </div>
                  ) : null}
                </div>
              </div>

              {gameOver ? (
                  <div className="win-lose-container">
                    <div className={`win-lose animate__animated ${displayLose || displayWin ? `animate__bounceIn` : ``}`}>
                      {displayWin ? <h1 className='gladiator-arena mv0 pv0'>You<br />won!</h1> : null }
                      {displayLose ? <h1 className='gladiator-arena mv0 pv0'>You<br />lost!</h1> : null }
                      {displayTie ? <h1 className='gladiator-arena mv0 pv0'>Tie<br />game!</h1> : null }
                      {(gameSummary && displayWin) && (player && opponent) ? (
                        <GameSummaryDisplay player={player} opponent={opponent} gameSummary={gameSummary} mode='winner' />
                      ) : null}
                      {(gameSummary && displayLose) && (player && opponent) ? (
                        <GameSummaryDisplay player={player} opponent={opponent} gameSummary={gameSummary} mode='loser' />
                      ) : null}
                      {(gameSummary && displayTie) && (player && opponent) ? (
                        <GameSummaryDisplay player={player} opponent={opponent} gameSummary={gameSummary} mode='tie' />
                      ) : null}
                      <div className="tc">
                        {!battleRoomState?.singlePlayer ? (
                          <IonButton size='large' onClick={(e) => {
                            if (room) {
                              setAwaitingRematchResponse(true)
                              rematch(user, room);
                            }  
                          }} color={'transparent'}><span className="button-text">Rematch</span></IonButton>
                        ) : null}
                        <IonButton size='large' onClick={(e) => {
                          if (room) {
                            leave(user, room).then(() => {
                              resetGame(false)
                              if (battleRoomState?.singlePlayer) {
                                router?.push('/')
                              } else {
                                router?.push('/play')                            
                              }
                            });
                          }
                        }} color={'transparent'}><span className="button-text">Leave</span></IonButton>
                      </div>
                    </div>
                  </div>
                ) : null}
              </>
            ) : null}

            {/* Player Cards */}
            <div className={`player-cards bottom card-count-${myCards ? myCards.length : 0}`}>
              {myCards?.map((card, i: number) =>  (
                <PlayerCard 
                  key={`player_card_${card.token_id}_${card.collection}_${i}`} 
                  display='player'
                  card={card}
                />
              ))}
              <div id="tilt-js-my-cards" />
            </div>

          </div>
        </IonContent>
        <div className="battle-fx" />
        </>
    </IonPage>
    </>
  );
};

export default Play;

// React
import React, { useContext } from 'react';
import { UserContext } from '../../Context';

// Ionic
import { IonButton, IonIcon } from '@ionic/react';
import { eyeOutline } from 'ionicons/icons';

// Components
import ParticleOverlay from './../../components/ui/ParticleOverlay';
import Version from './../../components/ui/Version';


const WelcomeBlock: React.FC = () => {
    const { setChooseTheme } = useContext(UserContext);
    return (
        <div className="gladiator-welcome-container">
            <div className={`w-100 mt3 flex items-center welcome-content-container relative`}>
                <div className="relative ma0 pa0 row welcome-content z-1 animate__animated animate__zoomIn">
                    <ParticleOverlay />
                    <h1 className='title pt-serif fw6 f3'><Version /></h1>
                    {/* <div className="change-theme-btn flex justify-center items-center">
                        <IonButton color={'primary'} onClick={() => setChooseTheme(true)} className='change-theme-button'>
                        <span className="button-text">Change Theme</span> <IonIcon slot="end" icon={eyeOutline} color='dark'></IonIcon> 
                        </IonButton>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default WelcomeBlock;
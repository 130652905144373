import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../Context';
import { IonButton, IonContent, IonPage } from '@ionic/react';
import ShopItemForm from '../components/forms/ShopItemForm';
import CollectionForm from '../components/forms/CollectionForm';
import BackgroundForm from '../components/forms/BackgroundsForm';
import { getItemsForSale, loadCollections, loadBackgrounds } from '../supabase/api';
import { CollectionRow, ShopItemRow, BackgroundRow } from '../supabase/types'; // Import BackgroundRow type
import './Admin.scss';

interface Props {
    // Define your component's props here
}

export const maximus = `0x4906B36EDcAD5b6F7D6b4a0872cF183e0F72Fc61`;

const Admin: React.FC<Props> = () => {

    const { user } = useContext(UserContext);

    // Collections
    const [collections, setCollections] = useState<CollectionRow[]>([]);
    const [collection, setCollection] = useState<CollectionRow | null>(null);
    const [collectionForm, setCollectionForm] = useState<boolean>(false);

    // Shop items
    const [shopItems, setShopItems] = useState<ShopItemRow[]>([]);
    const [shopItem, setShopItem] = useState<ShopItemRow | null>(null);
    const [shopItemForm, setShopItemForm] = useState<boolean>(false);

    // Backgrounds
    const [backgrounds, setBackgrounds] = useState<BackgroundRow[]>([]);
    const [background, setBackground] = useState<BackgroundRow | null>(null);
    const [backgroundForm, setBackgroundForm] = useState<boolean>(false);

    // Load Collections and Shop Items
    useEffect(() => {
        loadCollections().then((res) => setCollections(res));
        getItemsForSale().then((res) => setShopItems(res));
        loadBackgrounds().then((res) => setBackgrounds(res)); // Load backgrounds
    }, []);

    useEffect(() => {
        console.log({ collections, shopItems, backgrounds });
    }, [collections, shopItems, backgrounds]);

    if (user?.wallet !== maximus) {
        return (
            <div>
                <h1>Unauthorized</h1>
            </div>
        );
    } else {
        return (
            <IonPage className="gladiator-page collection">
                <IonContent fullscreen className='transparent'>
                    <div className="w-100 h-100 relative z-4">
                        <div className="page-content">

                            {/* Collections */}
                            <div className="row mb4">
                                <div className="column small-12">
                                    <h2 className="gladiator-arena">Collections</h2>
                                    <div>
                                        <IonButton onClick={() => {
                                            setCollectionForm(!collectionForm);
                                            setCollection(null);
                                        }}>
                                            {collectionForm ? `Close Form` : `Add Collection`}
                                        </IonButton>
                                    </div>
                                </div>
                                <div className={`column small-12 ${collectionForm ? `` : `dn`}`}>
                                    <CollectionForm existingCollection={collection} onSubmit={() => {
                                        loadCollections().then((res) => {
                                            setCollections(res);
                                            setCollectionForm(false);
                                        });
                                    }} />
                                </div>
                                <div className={`column small-12  ${!collectionForm ? `` : `dn`}`}>
                                    <div className="row flex flex-wrap">
                                        {collections.map((item, index) => (
                                            <div key={index} className="column small-12 medium-4 flex">
                                                <div className="admin-form mv3 w-100">
                                                    <p>{item.slug}</p>
                                                    <h4>{item.name}</h4>
                                                    <p>{item.description}</p>
                                                    <p>{item.enabled ? `Enabled` : `Disabled`}</p>
                                                    <IonButton onClick={() => {
                                                        setCollection(item);
                                                        setCollectionForm(true);
                                                    }}>Edit</IonButton>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            {/* Shop Items */}
                            <div className="row mb4">
                                <div className="column small-12">
                                    <h2 className="gladiator-arena">Shop Items</h2>
                                    <div>
                                        <IonButton onClick={() => {
                                            setShopItemForm(!shopItemForm);
                                            setShopItem(null);
                                        }}>
                                            {shopItemForm ? `Close Form` : `Add Shop Item`}
                                        </IonButton>
                                    </div>
                                </div>
                                <div className={`column small-12 ${shopItemForm ? `` : `dn`}`}>
                                    <ShopItemForm collections={collections} existingItem={shopItem} onSubmit={() => {
                                        getItemsForSale().then((res) => {
                                            setShopItems(res);
                                            setShopItemForm(false);
                                        });
                                    }} />
                                </div>
                                <div className={`column small-12  ${!shopItemForm ? `` : `dn`}`}>
                                    <div className="row flex flex-wrap">
                                        {shopItems.map((item, index) => (
                                            <div key={index} className="column small-12 medium-4 flex">
                                                <div className="admin-form mv3 w-100">
                                                    <p>{collections.find(c => c.uuid === item.collection)?.name}</p>
                                                    <h4>{item.name}</h4>
                                                    <p>{item.description}</p>
                                                    <p>{item.cost_eth?.toLocaleString()} ETH</p>
                                                    <p>{item.cost_usdc?.toLocaleString()} ERC20</p>
                                                    <p>{item.cost_gladii?.toLocaleString()} GLADII</p>
                                                    <textarea rows={3} className='w-100 bg-transparent b--white-10 pa2 mb3 lh-copy' readOnly value={JSON.stringify(item.metadata)} />
                                                    <IonButton onClick={() => {
                                                        setShopItem(item);
                                                        setShopItemForm(true);
                                                    }}>Edit</IonButton>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            {/* Backgrounds */}
                            <div className="row mb6">
                                <div className="column small-12">
                                    <h2 className="gladiator-arena">Backgrounds</h2>
                                    <div>
                                        <IonButton onClick={() => {
                                            setBackgroundForm(!backgroundForm);
                                            setBackground(null);
                                        }}>
                                            {backgroundForm ? `Close Form` : `Add Background`}
                                        </IonButton>
                                    </div>
                                </div>
                                <div className={`column small-12 ${backgroundForm ? `` : `dn`}`}>
                                    <BackgroundForm collections={collections} existingBackground={background} onSubmit={() => {
                                        loadBackgrounds().then((res) => {
                                            setBackgrounds(res);
                                            setBackgroundForm(false);
                                        });
                                    }} />
                                </div>
                                <div className={`column small-12  ${!backgroundForm ? `` : `dn`}`}>
                                    <div className="row flex flex-wrap">
                                        {backgrounds.map((item, index) => (
                                            <div key={index} className="column small-12 medium-4 flex">
                                                <div className="admin-form mv3 w-100">
                                                    <h4>{item.name}</h4>
                                                    <p>{item.slug}</p>
                                                    <p>{item.deleted ? `Deleted` : `Active`}</p>
                                                    <IonButton onClick={() => {
                                                        setBackground(item);
                                                        setBackgroundForm(true);
                                                    }}>Edit</IonButton>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className="w-100 db mv6 pv6"></div>

                        </div>
                    </div>
                </IonContent>
            </IonPage>
        );
    }
};

export default Admin;

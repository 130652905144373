// React
import { useContext, useEffect, useState } from "react";

// Interfaces
import { TopPlayers } from "../interfaces/Leaderboard.interface";

// Colyseus
import { TokenMetadata } from "../generated/TokenMetadata";

// Components
import PlayerCardDisplay from "../components/play/PlayerCardDisplay";

// Services
import { fetchTokenByIDandCollectionName } from "../services/Wallet";

// Utils
import { getOrdinalNumber, shortenWalletAddress } from "../Utils";
import { CollectionSlug } from "../config/collections";
import { UserContext } from "../Context";

interface LeaderboardPlacementProps {
    placement: number;
    player: TopPlayers;
    stats?: boolean;
}

const LeaderboardPlacement: React.FC<LeaderboardPlacementProps> = ({ placement, player, stats = true }) => {

    const { collections } = useContext(UserContext);
    const [ card, setCard ] = useState<TokenMetadata | null>(null);

    useEffect(() => {
        if (player?.token_id && collections.length && player?.collection) {
            fetchTokenByIDandCollectionName(player?.token_id, collections, player?.collection as CollectionSlug).then((t) => {
                const token = new TokenMetadata();
                token.token_id = t.token_id;
                token.name = t.name;
                token.description = t.description;
                token.image = t.image;
                token.attributes = t.attributes;
                token.flipped = true;
                token.collection = t.collection;
                setCard(token);
            });
        }
    }, [player?.collection, player?.token_id, collections]);

    return (
        <div className="place-container">
            <div className="player-card-container relative z-2">
            {card && 
                <PlayerCardDisplay
                  card={card}
                />}

            </div>
            {stats ? (
                <div className={`placement-block placement-${placement} relative z-1 flex flex-column justify-between animate__animated animate__zoomIn`}>
                    <div>
                        <h2 className={`gladiator-arena mb0 f${placement}`}>{getOrdinalNumber(placement)}<br />place</h2>
                        <div className='bb b--white-20 bw2 w4 mb3 center' />
                        <div className="place-wallet f4">
                            <span className="gladiator-arena">{shortenWalletAddress(player?.wallet_id)}</span>
                        </div>
                    </div>
                    <div className="flex flex-wrap justify-center items-center">
                        <div className="stat ma3">
                            <div className="gladiator-arena f3">{player?.total_wins}</div>
                            <div className="gladiator-arena">Wins</div>
                        </div>
                        <div className="stat ma3">
                            <div className="gladiator-arena f3">{player?.total_losses}</div>
                            <div className="gladiator-arena">Losses</div>
                        </div>
                        <div className="stat ma3">
                            <div className="gladiator-arena f3">{player?.ties}</div>
                            <div className="gladiator-arena">Ties</div>
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    )

}

export default LeaderboardPlacement;
// React
import React, { useState, useEffect, useContext } from 'react';

// Ionic
import {
    IonButton,
    IonInput,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonTextarea,
} from '@ionic/react';

// Supabase
import { CollectionRow, ShopItemRow } from '../../supabase/types';
import { createShopItem, updateShopItem } from '../../supabase/api-admin';
import { UserContext } from '../../Context';

interface ShopItemFormProps {
    collections: CollectionRow[];
    existingItem?: ShopItemRow | null;
    onSubmit: (payload: any) => void;
}

const initialFormState = {
    collection: '',
    cost_eth: '',
    cost_usdc: '',
    cost_gladii: '', 
    description: '',
    metadata: JSON.stringify({}, null, 2),
    name: '',
    type: '',
};

const ShopItemForm: React.FC<ShopItemFormProps> = ({ collections, existingItem, onSubmit }) => {

    const { setAlertHeader, setAlertMessage, setAlertModal, setWaitModal } = useContext(UserContext);


    const itemTypes = ['frames', 'abilities', 'wearables', 'collectibles'];

    // Form Data
    const [formState, setFormState] = useState<any>({
        collection: existingItem?.collection || '',
        cost_eth: existingItem?.cost_eth?.toString() || '',
        cost_usdc: existingItem?.cost_usdc?.toString() || '',
        cost_gladii: existingItem?.cost_gladii?.toString() || '', // Added field for Gladii
        description: existingItem?.description || '',
        metadata: JSON.stringify(existingItem?.metadata || {}, null, 2), // Ensure metadata is a formatted JSON string
        name: existingItem?.name || '',
        type: existingItem?.type || '',
    });

    useEffect(() => {
        if (existingItem) {
            setFormState({
                ...existingItem,
                cost_eth: existingItem.cost_eth?.toString() || '',
                cost_usdc: existingItem.cost_usdc?.toString() || '',
                cost_gladii: existingItem.cost_gladii?.toString() || '', // Handle Gladii for existing item
                metadata: JSON.stringify(existingItem.metadata || {}, null, 2), // Format JSON on load
            });
        } else {
            setFormState(initialFormState);
        }
    }, [existingItem]);

    useEffect(() => {
        console.log('formState', formState);
    }, [formState]);

    /**
     * Handle form input changes
     * @param e 
     */
    const handleChange = (e: CustomEvent) => {
        const { name, value } = e.target as HTMLInputElement;
        setFormState({
            ...formState,
            [name]: value,
        });
    };

    const handleMetadataChange = (e: CustomEvent) => {
        const { value } = e.target as HTMLTextAreaElement;
        // Remove new lines, tabs and flatted the JSON string
        const metadata = value.replace(/(\r\n|\n|\r|\t)/gm, '').replace(/\s+/g, ' ');
        try {
            setFormState({
                ...formState,
                metadata,
            });
        } catch (error) {
            console.error("Invalid JSON:", error);
            // Optionally, set an error message in the state to display to the user
        }
    };
    

    /**
     * Handle select input changes
     * @param e 
     */
    const handleSelectChange = (e: any) => {
        const name = e.target.name;
        const value = e.detail.value;

        setFormState({
            ...formState,
            [name]: value,
        });
    };

    /**
     * Handle form submission
     */
    const handleSubmit = () => {
        setWaitModal(true);
        const payload = {
            ...formState,
            cost_eth: parseFloat(formState.cost_eth) || null,
            cost_usdc: parseFloat(formState.cost_usdc) || null,
            cost_gladii: parseFloat(formState.cost_gladii) || null,
            metadata: JSON.parse(formState.metadata),
        };

        if (!existingItem) {
            createShopItem(payload).then((res) => {
                onSubmit(payload);
                setWaitModal(false);
                setAlertHeader('Success');
                setAlertMessage('Item created successfully');
                setAlertModal(true);
                setFormState(initialFormState);
            }).catch((error) => {
                setWaitModal(false);
                setAlertHeader('Error');
                setAlertMessage(error.message);
                setAlertModal(true);
            });
        } else {
            updateShopItem(payload).then((res) => {
                onSubmit(payload);
                setWaitModal(false);
                setAlertHeader('Success');
                setAlertMessage('Item updated successfully');
                setAlertModal(true);
                setFormState(initialFormState);
            }).catch((error) => {
                setWaitModal(false);
                setAlertHeader('Error');
                setAlertMessage(error.message);
                setAlertModal(true);
            });
        }
    };

    return (
        <form className='admin-form'>

            <IonItem lines="full" className="ion-no-padding" mode="md">
                <IonLabel position="stacked">Collection</IonLabel>
                <IonSelect
                    name="collection"
                    value={formState.collection}
                    onIonChange={handleSelectChange}
                    interfaceOptions={{ mode: 'md' }}
                    interface='popover'
                    placeholder='Select Collection'
                >
                    {collections.map((collection) => (
                        <IonSelectOption key={collection.slug} value={collection.uuid}>
                            {collection.name}
                        </IonSelectOption>
                    ))}
                </IonSelect>
            </IonItem>

            <IonItem lines="full" className="ion-no-padding" mode="md">
                <IonLabel position="stacked">Type</IonLabel>
                <IonSelect
                    name="type"
                    value={formState.type}
                    onIonChange={handleSelectChange}
                    interfaceOptions={{ mode: 'md' }} // Set to md style popover
                    interface='popover'
                    placeholder='Select Type'
                >
                    {itemTypes.map((itemType) => (
                        <IonSelectOption key={itemType} value={itemType}>
                            {itemType}
                        </IonSelectOption>
                    ))}
                </IonSelect>
            </IonItem>

            <IonItem lines="full" className="ion-no-padding" mode="md">
                <IonLabel position="stacked">Name</IonLabel>
                <IonInput
                    name="name"
                    value={formState.name}
                    onIonInput={handleChange}
                    placeholder="Enter item name"
                    mode="md"
                />
            </IonItem>
            
            <IonItem lines="full" className="ion-no-padding" mode="md">
                <IonLabel position="stacked">Description</IonLabel>
                <IonTextarea
                    name="description"
                    value={formState.description}
                    onIonInput={handleChange}
                    placeholder="Enter item description"
                    mode="md"
                />
            </IonItem>

            <IonItem lines="full" className="ion-no-padding" mode="md">
                <IonLabel position="stacked">Cost (ETH)</IonLabel>
                <IonInput
                    type="number"
                    name="cost_eth"
                    value={formState.cost_eth}
                    onIonInput={handleChange}
                    placeholder="Enter cost in ETH"
                    mode="md"
                />
            </IonItem>

            <IonItem lines="full" className="ion-no-padding" mode="md">
                <IonLabel position="stacked">Cost (USDC)</IonLabel>
                <IonInput
                    type="number"
                    name="cost_usdc"
                    value={formState.cost_usdc}
                    onIonInput={handleChange}
                    placeholder="Enter cost in USDC"
                    mode="md"
                />
            </IonItem>

            <IonItem lines="full" className="ion-no-padding" mode="md">
                <IonLabel position="stacked">Cost (Gladii)</IonLabel>
                <IonInput
                    type="number"
                    name="cost_gladii"
                    value={formState.cost_gladii}
                    onIonInput={handleChange}
                    placeholder="Enter cost in Gladii"
                    mode="md"
                />
            </IonItem>

            <IonItem lines="full" className="ion-no-padding" mode="md">
                <IonLabel position="stacked">Metadata (JSON)</IonLabel>
                <IonTextarea
                    name="metadata"
                    value={formState.metadata}
                    onIonInput={handleMetadataChange}
                    placeholder="Enter metadata"
                    rows={10}
                    mode="md"
                />
            </IonItem>

            <IonButton expand="block" onClick={handleSubmit} mode="md">
                {existingItem ? 'Update Item' : 'Create Item'}
            </IonButton>

        </form>
    );
};

export default ShopItemForm;

import React, { useState, useEffect, useContext } from 'react';
import {
    IonButton,
    IonCheckbox,
    IonInput,
    IonItem,
    IonLabel,
    IonTextarea,
} from '@ionic/react';
import { CollectionRow } from '../../supabase/types';
import { createCollection, updateCollection } from '../../supabase/api-admin';
import { UserContext } from '../../Context';

interface CollectionFormProps {
    existingCollection?: CollectionRow | null;
    onSubmit: (payload: any) => void;
}

const initialFormState = {
    name: '',
    description: '',
    discord: '',
    twitter: '',
    website: '',
    magic_eden: '',
    opensea: '',
    rarible: '',
    mainnet_address: '',
    mainnet_baseuri: '',
    mainnet_extension: '',
    mainnet_network: '',
    testnet_address: '',
    testnet_baseuri: '',
    testnet_extension: '',
    testnet_network: '',
    enabled: false,
    local: false,
    deleted: false,
};

const CollectionForm: React.FC<CollectionFormProps> = ({ existingCollection, onSubmit }) => {

    const { setAlertHeader, setAlertMessage, setAlertModal, setWaitModal } = useContext(UserContext);

    // Form Data
    const [formState, setFormState] = useState<any>({
        ...initialFormState,
        ...existingCollection,
    });

    useEffect(() => {
        if (existingCollection) {
            setFormState({
                ...existingCollection,
            });
        } else {
            setFormState(initialFormState);
        }
    }, [existingCollection]);

    useEffect(() => {
        console.log('formState', formState);
    }, [formState]);

    /**
     * Handle form input changes
     * @param e 
     */
    const handleChange = (e: CustomEvent) => {
        const { name, value } = e.target as HTMLInputElement;
        setFormState({
            ...formState,
            [name]: value,
        });
    };

    const handleCheckboxChange = (e: CustomEvent) => {
        const { name, checked } = e.target as HTMLInputElement;
        setFormState({
            ...formState,
            [name]: checked,
        });
    };

    /**
     * Handle form submission
     */
    const handleSubmit = () => {
        setWaitModal(true);
        const payload = {
            ...formState,
        };

        if (!existingCollection) {
            createCollection(payload).then((res) => {
                onSubmit(payload);
                setWaitModal(false);
                setAlertHeader('Success');
                setAlertMessage('Collection created successfully');
                setAlertModal(true);
                setFormState(initialFormState);
            }).catch((error) => {
                setWaitModal(false);
                setAlertHeader('Error');
                setAlertMessage(error.message);
                setAlertModal(true);
            });
        } else {
            updateCollection(payload).then((res) => {
                onSubmit(payload);
                setWaitModal(false);
                setAlertHeader('Success');
                setAlertMessage('Collection updated successfully');
                setAlertModal(true);
                setFormState(initialFormState);
            }).catch((error) => {
                setWaitModal(false);
                setAlertHeader('Error');
                setAlertMessage(error.message);
                setAlertModal(true);
            });
        }
    };

    return (
        <form className='admin-form'>
            <IonItem lines="full" className="ion-no-padding" mode="md">
                <IonLabel position="stacked">Name</IonLabel>
                <IonInput
                    name="name"
                    value={formState.name}
                    onIonInput={handleChange}
                    placeholder="Enter collection name"
                    mode="md"
                />
            </IonItem>

            <IonItem lines="full" className="ion-no-padding" mode="md">
                <IonLabel position="stacked">Description</IonLabel>
                <IonTextarea
                    name="description"
                    value={formState.description}
                    onIonInput={handleChange}
                    placeholder="Enter collection description"
                    mode="md"
                />
            </IonItem>

            <IonItem lines="full" className="ion-no-padding" mode="md">
                <IonLabel position="stacked">Discord</IonLabel>
                <IonInput
                    name="discord"
                    value={formState.discord}
                    onIonInput={handleChange}
                    placeholder="Enter Discord link"
                    mode="md"
                />
            </IonItem>

            <IonItem lines="full" className="ion-no-padding" mode="md">
                <IonLabel position="stacked">Twitter</IonLabel>
                <IonInput
                    name="twitter"
                    value={formState.twitter}
                    onIonInput={handleChange}
                    placeholder="Enter Twitter link"
                    mode="md"
                />
            </IonItem>

            <IonItem lines="full" className="ion-no-padding" mode="md">
                <IonLabel position="stacked">Website</IonLabel>
                <IonInput
                    name="website"
                    value={formState.website}
                    onIonInput={handleChange}
                    placeholder="Enter Website link"
                    mode="md"
                />
            </IonItem>

            <IonItem lines="full" className="ion-no-padding" mode="md">
                <IonLabel position="stacked">Magic Eden</IonLabel>
                <IonInput
                    name="magic_eden"
                    value={formState.magic_eden}
                    onIonInput={handleChange}
                    placeholder="Enter Magic Eden link"
                    mode="md"
                />
            </IonItem>

            <IonItem lines="full" className="ion-no-padding" mode="md">
                <IonLabel position="stacked">OpenSea</IonLabel>
                <IonInput
                    name="opensea"
                    value={formState.opensea}
                    onIonInput={handleChange}
                    placeholder="Enter OpenSea link"
                    mode="md"
                />
            </IonItem>

            <IonItem lines="full" className="ion-no-padding" mode="md">
                <IonLabel position="stacked">Rarible</IonLabel>
                <IonInput
                    name="rarible"
                    value={formState.rarible}
                    onIonInput={handleChange}
                    placeholder="Enter Rarible link"
                    mode="md"
                />
            </IonItem>

            <IonItem lines="full" className="ion-no-padding" mode="md">
                <IonLabel position="stacked">Mainnet Address</IonLabel>
                <IonInput
                    name="mainnet_address"
                    value={formState.mainnet_address}
                    onIonInput={handleChange}
                    placeholder="Enter Mainnet address"
                    mode="md"
                />
            </IonItem>

            <IonItem lines="full" className="ion-no-padding" mode="md">
                <IonLabel position="stacked">Mainnet Base URI</IonLabel>
                <IonInput
                    name="mainnet_baseuri"
                    value={formState.mainnet_baseuri}
                    onIonInput={handleChange}
                    placeholder="Enter Mainnet base URI"
                    mode="md"
                />
            </IonItem>

            <IonItem lines="full" className="ion-no-padding" mode="md">
                <IonLabel position="stacked">Mainnet Extension</IonLabel>
                <IonInput
                    name="mainnet_extension"
                    value={formState.mainnet_extension}
                    onIonInput={handleChange}
                    placeholder="Enter Mainnet extension"
                    mode="md"
                />
            </IonItem>

            <IonItem lines="full" className="ion-no-padding" mode="md">
                <IonLabel position="stacked">Mainnet Network</IonLabel>
                <IonInput
                    name="mainnet_network"
                    value={formState.mainnet_network}
                    onIonInput={handleChange}
                    placeholder="Enter Mainnet network"
                    mode="md"
                />
            </IonItem>

            <IonItem lines="full" className="ion-no-padding" mode="md">
                <IonLabel position="stacked">Testnet Address</IonLabel>
                <IonInput
                    name="testnet_address"
                    value={formState.testnet_address}
                    onIonInput={handleChange}
                    placeholder="Enter Testnet address"
                    mode="md"
                />
            </IonItem>

            <IonItem lines="full" className="ion-no-padding" mode="md">
                <IonLabel position="stacked">Testnet Base URI</IonLabel>
                <IonInput
                    name="testnet_baseuri"
                    value={formState.testnet_baseuri}
                    onIonInput={handleChange}
                    placeholder="Enter Testnet base URI"
                    mode="md"
                />
            </IonItem>

            <IonItem lines="full" className="ion-no-padding" mode="md">
                <IonLabel position="stacked">Testnet Extension</IonLabel>
                <IonInput
                    name="testnet_extension"
                    value={formState.testnet_extension}
                    onIonInput={handleChange}
                    placeholder="Enter Testnet extension"
                    mode="md"
                />
            </IonItem>

            <IonItem lines="full" className="ion-no-padding" mode="md">
                <IonLabel position="stacked">Testnet Network</IonLabel>
                <IonInput
                    name="testnet_network"
                    value={formState.testnet_network}
                    onIonInput={handleChange}
                    placeholder="Enter Testnet network"
                    mode="md"
                />
            </IonItem>

            <IonItem lines="full" className="ion-no-padding" mode="md">
                <IonLabel position="stacked">Enabled</IonLabel>
                <IonCheckbox
                    name="enabled"
                    checked={formState.enabled}
                    onIonChange={handleCheckboxChange}
                    mode="md"
                />
            </IonItem>

            <IonItem lines="full" className="ion-no-padding" mode="md">
                <IonLabel position="stacked">Local</IonLabel>
                <IonCheckbox
                    name="local"
                    checked={formState.local}
                    onIonChange={handleCheckboxChange}
                    mode="md"
                />
            </IonItem>

            <IonItem lines="full" className="ion-no-padding" mode="md">
                <IonLabel position="stacked">Deleted</IonLabel>
                <IonCheckbox
                    name="deleted"
                    checked={formState.deleted}
                    onIonChange={handleCheckboxChange}
                    mode="md"
                />
            </IonItem>

            <IonButton expand="block" onClick={handleSubmit} mode="md">
                {existingCollection ? 'Update Collection' : 'Create Collection'}
            </IonButton>
        </form>
    );
};

export default CollectionForm;

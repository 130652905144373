// React
import { UserContext } from '../Context';
import { useContext, useEffect, useState } from 'react';

// Ionic
import { getPlatforms, IonAlert, IonButton, IonContent, IonInput, IonModal, IonSpinner } from '@ionic/react';

// Interfaces
import { UserState } from '../interfaces/User.interface';

// Styles
import './Home.scss';

// Images
import card from './../images/gladiator/gladiator-card.png'
import logo from './../images/gladiator/gladiator-logo.png'

// Components
import Version from '../components/ui/Version';

// Utils
import { shortenWalletAddress, tilt } from '../Utils';

// Web3
import { isFrame, isMetaMask, Web3Provider, Web3Providers } from '../web3/web3';

/**
 * Connected SVG (animated checkmark)
 * @returns JSX Element
 */
function ConnectedSVG() {
    return (
        <svg className="checkmark ml2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
            <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
        </svg>
    );
}

const Login: React.FC = () => {

    const platforms = getPlatforms();
    const { user, loggedIn, tokensLoaded, playDemo, chooseProvider, setWaitModal } = useContext<UserState>(UserContext);
    const [modal, displayModal] = useState(false);
    const [activating, setActivating] = useState<Web3Provider | null>();
    const [showAlert, setShowAlert] = useState<Web3Provider | null>();
    
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isTouched, setIsTouched] = useState(false);
    const [email, setEmail] = useState<string>('');

    const markTouched = () => {
        setIsTouched(true);
    };

    /**
     * Handle Provider Click
     * @param provider Web3 Provider
     */
    const handleProviderClick = (provider: Web3Provider) => {
        console.log('Provider:', provider);
        if (provider.name === 'MetaMask' && !isMetaMask) {
            setShowAlert(provider);
        } else if (provider.name === 'Frame' && !isFrame) {
            setShowAlert(provider);
        } else {
            chooseProvider(provider);
            setActivating(provider);

            // Deeplink for iOS users
            if (provider.name === 'MetaMask' && platforms.includes(`ios`) && !!navigator.userAgent.match(/Version\/[\d.]+.*Safari/)) {
                window.open("https://metamask.app.link/dapp/gladiator.brokenreality.com");
            }
        }
    };

    /**
     * Handle Alert Confirm
     */
    const handleAlertConfirm = () => {
        setWaitModal(false);
        setShowAlert(undefined);
        if (showAlert?.name === 'MetaMask') {
            window.open('https://metamask.io/download.html', '_blank');
        }
        if (showAlert?.name === 'Frame') {
            window.open('https://frame.sh/', '_blank');
        }
    };

    /**
     * Handle Alert Dismiss
     */
    const handleAlertDismiss = () => {
        setWaitModal(false);
        setShowAlert(undefined);
    };

    useEffect(() => {
        if (activating && user?.wallet) {
          setActivating(null);
          setTimeout(() => {
            displayModal(true);
          }, 250);
        }
    }, [platforms, activating, user?.wallet])

    // Initialize tilt on mount
    useEffect(() => {
        initTilt()
    }, [])

    /**
     * Initialize vanilla tilt
     */
    const initTilt = (timeout: number = 100) => {
        setTimeout(() => {
            tilt('tilt-js-welcome');
        }, timeout)
    }

    return (
        <>
        <div className="gladiator-wallet-container">
            <div className={`animate__animated ${!tokensLoaded ? `animate__zoomIn` : `animate__zoomOut`} flex flex-column`}>
                <div className="mb3 tc">
                    <div className="gladiator-logo"
                        data-tilt
                        data-tilt-max="12"
                        data-tilt-full-page-listening
                        data-tilt-reverse="true"
                    >
                        <img src={card} alt="" width={350} className='left' />
                        <img src={card} alt="" width={350} className='right' />
                        <img src={logo} alt="" width={350} className='logo' />
                    </div>
                </div>
                <div className='db tc o-90 lh-copy mb3'>
                    <p className='mb0 gladiator-arena f4'>An unparalleled digital<br />collectible card game experience.</p>
                </div>
                <div className="row">
                    <div className="column small-12 tc">
                        <IonButton id="connect-wallet-button-header" className="white-outline-wallet animate__animated animate__zoomIn" color={user?.wallet === null ? `primary` : 'light'} onClick={() => displayModal(true)}>                           
                            <span className="button-text f4">Login</span>
                        </IonButton>
                    </div>
                    <div className="column small-12 tc mt3">
                        <IonButton id="connect-wallet-button-header" className="white-outline-wallet animate__animated animate__zoomIn" color={user?.wallet === null ? `primary` : 'light'} onClick={() => playDemo()}>
                            <span className="button-text f4">Play Demo</span>
                        </IonButton>
                    </div>
                </div>
                <div className="row">
                    <div className="column small-12 o-50 mt4 f6 tc">
                        <p className='mb0'></p>
                        <p className='mb0'>&copy; {new Date().getFullYear()} Brokenreality Inc. <Version /></p>
                    </div>
                </div>
            </div>

        </div>
        <div id="tilt-js-welcome"></div>

            {/* Connect Modal */}
            <IonModal 
                className="connect-wallet"
                isOpen={modal}
                backdropDismiss={true}
                onDidDismiss={() => displayModal(false)}
            >
                <IonContent className="relative">
                <div className='relative modal-content z-1'>
                        <div className="row mv2">
                            <div className="column small-12">
                                <div className="ph3">
                                    <h1 className="mb0 pr3 gladiator-arena"><span className="dib pt2">Connect Wallet</span></h1>
                                    {user?.wallet && loggedIn ?
                                        <h2 className="mt0 lh-copy f6 fw6">Wallet: {shortenWalletAddress(user.wallet)}</h2>
                                        :
                                        <h2 className="mt0 lh-copy f6 pt-serif ttu fw6">Choose provider</h2>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row mb4 flex justify-center">
                            {Web3Providers.map(provider => {
                                if (provider.connect) {
                                    const connected = provider.name === user?.provider?.name;
                                    const disabled = ((user?.wallet && user?.provider?.id && loggedIn) && (user?.provider?.id !== provider.id)) ? true : false;
                                    return (
                                        <div key={`${provider.id}`} className="column small-12 flex justify-center ma0 pa0">
                                            <IonButton
                                                className="w5"
                                                disabled={disabled}
                                                color="light"
                                                onClick={() => {
                                                    handleProviderClick(provider)
                                                }}>
                                                <span className='dib flex items-center'><img width={25} height={25} src={provider.image} alt="" className="wallet-logo mr2" /> <span className='button-text'>{provider.name}</span></span>
                                                &nbsp;{(activating && activating.id === provider.id) && <IonSpinner className="dn" name="crescent" />}{((!activating && connected && loggedIn) && (provider.id === user?.provider?.id)) && (<ConnectedSVG />)}
                                            </IonButton>
                                        </div>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                            
                            {/* <div className="column small-12 flex justify-center items-center mt3 fade-in">
                                <p className='lh-copy f6 pt-serif ttu fw6 mv0'>Or</p>
                            </div>
                            <div className="column small-12 flex justify-center items-center mt3 fade-in">
                                <form className="w-50 ph3" onSubmit={(e) => {
                                    e.preventDefault();
                                }}>
                                <IonInput
                                    labelPlacement="floating" 
                                    fill="outline" 
                                    placeholder="Continue with email"
                                    value={email}
                                    helperText="Enter a valid email"
                                    errorText="Invalid email"
                                    type='email'
                                    required
                                    onIonBlur={() => markTouched()}
                                    onInput={e => {
                                        const value = (e.target as HTMLInputElement).value
                                        setEmail(value)
                                    }}
                                />
                                </form>
                            </div> */}

                        </div>

                        <IonAlert
                            isOpen={showAlert !== null && showAlert !== undefined}
                            onDidDismiss={handleAlertDismiss}
                            header={`${showAlert?.name}`}
                            message={`${showAlert?.name} is not installed. Would you like to install it?`}
                            buttons={[
                                {
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'secondary',
                                    handler: handleAlertDismiss
                                },
                                {
                                    text: 'Install',
                                    handler: handleAlertConfirm
                                }
                            ]}
                        />

                    </div>
                </IonContent>
            </IonModal>

        </>
    );
};

export default Login;

// Axios
import axios, { AxiosInstance, AxiosRequestHeaders, AxiosResponse } from 'axios';

// Interfaces
import { Routes } from './interfaces/API.interface';

// Types
import apiRequestTypes from './types/APIRequestTypes.type';
import apiEndpoints from './types/APIEndpoints.type';
import { dc } from './Utils';

/**
 * Environment
 */
export let envmode: 'dev' | 'prod' = process.env.NODE_ENV === 'development' ? 'dev' : 'prod';

/**
 * App URL
 * @returns string
 */
export const app = (): string => {
    return `${process.env.REACT_APP_GLADIATOR_DOMAIN}`
}

/**
 * API Key
 * @returns string
 */
export const apiKey = (): string => {
    return `${dc(process.env.REACT_APP_SUPABASE_KEY)}`
}

/**
 * API Address
 * @returns string
 */
export const api = (): string => {
    return `${dc(process.env.REACT_APP_SUPABASE_URL)}`
}

/**
 * Axios Instance
 * @param authBearer Logged in user's JWT
 * @returns Axios instance to begin promise chain
 */
export const axiosInstance = (authBearer?: string) => {

    const baseURL: string = `${api()}`
    let headers: AxiosRequestHeaders | any

    // No JWT
    if (authBearer === '') {

        // Default headers
        headers = {
            'apiKey': `${apiKey()}`,
        }

    } else {

        // Logged in headers
        headers = {
            'apiKey': `${apiKey()}`,
            'Authorization': `Bearer ${authBearer}`
        }

    }

    // Create Axios instance
    return axios.create({
        baseURL,
        headers,
    })

}

/**
 * Axios Instance to Handle Form Data Requests
 * @param authBearer Logged in user's JWT
 * @returns Axios instance to begin form data promise chain
 */
export const axiosFormData = (authBearer?: string) => {

    const baseURL: string = `${api()}`
    let headers: AxiosRequestHeaders | any

    // No JWT
    if (authBearer === '') {

        // Default headers
        headers = {
            'Content-Type': 'multipart/form-data',
            'x-api-key': `${apiKey()}`,
        }

    } else {

        // Logged in headers
        headers = {
            'Content-Type': 'multipart/form-data',
            'x-api-key': `${apiKey()}`,
            'Authorization': `Bearer ${authBearer}`
        }

    }

    // Create Axios instance
    return axios.create({
        baseURL,
        headers
    })

}

/**
 * Check if API has responded with 200
 * @param res
 * @returns boolean
 */
export const apiResponseOK = (res: AxiosResponse) => {
    return (res.status === 200 || res.status === 201) ? true : false;
}

/**
 * Check if API has responded with 404
 * @param res
 * @returns boolean
 */
export const apiResponseNotFound = (res: AxiosResponse) => {
    return (res.status === 404) ? true : false;
}

/**
 * Send API request
 * @param type Type of API request
 * @param path Path of API request
 * @param jwt JWT Token for Authorized User
 * @param data Payload to accompany the API request
 * @param timeout Optional timeout in ms
 * @returns Axios data object from AxiosResponse type
 */
export const apiRequest = (type: apiRequestTypes, path: apiEndpoints, jwt: string = '', data?: any, timeout: number = 10000) => {
    return new Promise<any>((resolve, reject) => {
        switch (type) {
            case 'GET':
                axiosInstance(jwt)
                    .get(`${api()}${path}`, { timeout })
                    .then((res: AxiosResponse) => {
                        apiResponseOK(res)
                            ? resolve(res.data)
                            : reject(res);
                    })
                    .catch((err) => reject(err));

                break;
            case 'POST':
                axiosInstance(jwt)
                    .post(`${api()}${path}`, data, { timeout })
                    .then((res: AxiosResponse) => {
                        apiResponseOK(res)
                            ? resolve(res.data)
                            : reject(res);
                    })
                    .catch((err) => reject(err));

                break;
            case 'PATCH':
                axiosInstance(jwt)
                    .patch(`${api()}${path}`, data, { timeout })
                    .then((res: AxiosResponse) => {
                        apiResponseOK(res)
                            ? resolve(res.data)
                            : reject(res);
                    })
                    .catch((err) => reject(err));

                break;
            case 'DELETE':
                axiosInstance(jwt)
                    .delete(`${api()}${path}`, { timeout })
                    .then((res: AxiosResponse) => {
                        apiResponseOK(res)
                            ? resolve(res.data)
                            : reject(res);
                    })
                    .catch((err) => reject(err));

                break;
            default:
                reject(`${type} is not supported`);
                break;
        }
    });
}

/**
 * Return Infura RPC WSS Provider Route
 * @returns string
 */
export const infuraWSS = (): string => {
    const rpcMainnet = `${process.env.REACT_APP_INFURA_WSS_MAINNET}`
    const rpcTestnet = `${process.env.REACT_APP_INFURA_WSS_SEPOLIA}`
    const infuraApiKey = dc(`${process.env.REACT_APP_INFURA_API_KEY}`)
    const mainnet = `${rpcMainnet}${infuraApiKey}`;
    const goerli = `${rpcTestnet}${infuraApiKey}`;
    const routes: Routes = {
        dev: goerli,
        prod: mainnet,
    };
    return routes[envmode];
}

/**
 * Return Infura RPC HTTP Provider Route
 * @returns string
 */
export const infuraHTTP = (): string => {
    const rpcMainnet = `${process.env.REACT_APP_INFURA_HTTP_MAINNET}`
    const rpcTestnet = `${process.env.REACT_APP_INFURA_HTTP_SEPOLIA}`
    const infuraApiKey = dc(`${process.env.REACT_APP_INFURA_API_KEY}`)
    const mainnet = `${rpcMainnet}${infuraApiKey}`;
    const goerli = `${rpcTestnet}${infuraApiKey}`;
    const routes: Routes = {
        dev: goerli,
        prod: mainnet,
    };
    return routes[envmode];
}

/**
 * Setup Axios Instance for external paths
 * @returns AxiosInstance
 */
export const externalRequest = (baseURL: string, headers?: any): AxiosInstance => {

    // Create Axios instance
    return axios.create({
        baseURL,
        headers,
    })

}

// React
import { useContext, useEffect, useState } from 'react';
import { PlayContext, PlayState } from '../../pages/PlayContext';
import { UserContext } from '../../Context';

// Interfaces
import { UserState } from '../../interfaces/User.interface';

// Ionic
import { IonButton, IonHeader, IonIcon } from '@ionic/react';
import { close, settingsSharp, walletSharp } from 'ionicons/icons';

// Images
import logoCoin from './../../images/gladiator/gladiator-coin.png'

// Utils
import { shortenWalletAddress } from '../../Utils';


const Header: React.FC = () => {
    
    const { router, user, collections, theme, settings, setSettings, audioPlaying, walletInfo, setWalletInfo, demo, logout } = useContext<UserState>(UserContext);
    const [mode, setMode] = useState<'home' | 'play'>('home');
    const [settingIcon, setSettingIcon] = useState<'settings' | 'speaker'>('speaker');
    const [logoSmall, setLogoSmall] = useState<string | null>(logoCoin);
    const { otherPlayerConnected }: PlayState = useContext(PlayContext);

    useEffect(() => {
        if (theme) {
            collections.forEach(collection => {
                if (collection.slug === theme && collection.logoSmall) {
                    setLogoSmall(collection.logoSmall);
                }
            });
        } else {
            setLogoSmall(null);
        }
    }, [theme, collections])

    useEffect(() => {
        if (router?.routeInfo.pathname.startsWith('/play/')) {
            setMode('play');
        } else {
            setMode('home');
        }
    }, [router?.routeInfo.pathname]);

    useEffect(() => {
        if (audioPlaying) {
            setSettingIcon('speaker');
        } else {
            setSettingIcon('settings');
        }
    }, [audioPlaying]);

    return (
        <>

        <IonHeader className={`${otherPlayerConnected ? `play` : ``}`}>
            <div className="w-100 flex justify-between items-center mv2">
                <IonButton 
                    id='settings-button'
                    color={`transparent`} 
                    className='ml3 relative z-2'
                    onClick={() => setSettings(!settings)}
                >
                <span className="show-for-large"><span className="button-text f5">Settings</span></span>
                {settingIcon === 'speaker' && (
                    <div className="sound-bars animate__animated animate__zoomIn">
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                    </div>
                )}
                {settingIcon === 'settings' && (<IonIcon slot="end" icon={settingsSharp} color='dark' className='animate__animated animate__zoomIn' />)}
                </IonButton>
                <div className="logo-container animate__animated animate__zoomIn relative">
                    <img src={logoSmall ? logoSmall : logoCoin} alt="" width={mode === 'home' ? 100 : 88} className='logo' />
                </div>
                <div>
                    {(user?.wallet) && (
                        <IonButton 
                            id='wallet-button'
                            color={`transparent`}
                            className='mr3 relative z-2'
                            onClick={() => setWalletInfo(!walletInfo)}
                        >
                        <span className="button-text f5 show-for-large">{shortenWalletAddress(user.wallet)}</span> <IonIcon slot="end" icon={walletSharp} color='dark'></IonIcon> 
                        </IonButton>                        
                    )}
                    <IonButton color={`transparent`} className='mr3 relative z-2' onClick={() => logout() }>
                    <span className="button-text f5 show-for-large">Logout</span> <IonIcon slot="end" icon={close} color='dark'></IonIcon> 
                    </IonButton>
                </div>
            </div>
        </IonHeader>
        </>
    );
};

export default Header;

import { useContext, useEffect, useRef, useState } from "react";
import { TokenMetadata } from "../../generated/TokenMetadata";
import { useSpring, animated } from 'react-spring';

import './Power.scss';
import { PlayContext, PlayState } from "../../pages/PlayContext";

interface Props {
    tokens: TokenMetadata[];
}

const Power: React.FC<Props> = ({ tokens }) => {
      
    const { myAbilities, opponentAbilities }: PlayState = useContext(PlayContext);

    const [defenseTotal, setDefenseTotal] = useState(0);
    const [defense, setDefense] = useState(0);
    const [defenseTotalCalc, setDefenseTotalCalc] = useState(0);

    const [healthTotal, setHealthTotal] = useState(0);
    const [health, setHealth] = useState(0);
    const [healthTotalCalc, setHealthTotalCalc] = useState(0);

    const [total, setTotal] = useState(0);
    const [life, setLife] = useState(0);
    const [lifeTotalCalc, setLifeTotalCalc] = useState(0);

    const [barClass, setBarClass] = useState('');
    const prevLifeRef = useRef(life);

    const { value: lifeSpring } = useSpring({
        from: { value: prevLifeRef.current },
        to: { value: life },
        config: { duration: 1000 },
    });

    /**
     * Update the circle progress bar
     * @param value Circle progress value
     * @param svgId SVG element ID
     * @returns void
     */
    function updateCircle(value: number, svgId: string): void {
        const circle = document.querySelector<SVGCircleElement>(`#${svgId} #bar`);

        if (!circle) {
            return;
        }

        let val = parseInt(value.toString(), 10);

        if (isNaN(val)) {
            val = 100;
        } else {
            const r = parseFloat(circle.getAttribute('r') || '0');
            const c = Math.PI * (r * 2);

            if (val < 0) { val = 0; }
            if (val > 100) { val = 100; }

            const pct = ((100 - val) / 100) * c;

            circle.style.strokeDashoffset = pct.toString();

            const cont = document.getElementById('cont');
            if (cont) {
                cont.setAttribute('data-pct', val.toString());
            } else {
                console.error('Container element with ID "cont" not found');
            }
        }
    }
    
    useEffect(() => {
        let defenseCalc = 0;
        let healthCalc = 0;
        let totalCalc = 0;

        tokens.forEach(token => {
            defenseCalc += token.stats.defense;
            healthCalc += token.stats.health;
        });

        totalCalc = defenseCalc + healthCalc;

        defenseCalc = Math.max(defenseCalc, 0); // Ensure defense is not less than 0
        setDefenseTotalCalc(defenseCalc);

        healthCalc = Math.max(healthCalc, 0); // Ensure health is not less than 0
        setHealthTotalCalc(healthCalc);

        totalCalc = Math.max(totalCalc, 0); // Ensure total is not less than 0
        setLifeTotalCalc(totalCalc);

        // If no abilities have been used, this is the beginning of the game
        if (myAbilities.length === 0 && opponentAbilities.length === 0) {
            setDefenseTotal(defenseCalc);
            setHealthTotal(healthCalc);
            setTotal(total);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokens, myAbilities, opponentAbilities])

    useEffect(() => {
        setDefense(defenseTotalCalc);
        setHealth(healthTotalCalc);
        setLife(lifeTotalCalc);   
    }, [defenseTotalCalc, healthTotalCalc, lifeTotalCalc]);

    useEffect(() => {
        if (life > prevLifeRef.current) {
            setBarClass('heal');
            setTimeout(() => setBarClass(''), 1000);
        } else if (life < prevLifeRef.current) {
            setBarClass('damage');
            setTimeout(() => setBarClass(''), 1000);
        }
        prevLifeRef.current = life;

        updateCircle(life, 'svg');

    }, [life, total]);

    return (
        <div className={`power-container ${barClass}`}>
            <div className="defense">
            <CircleProgressBar progress={Math.round(Math.max(Math.min((defense / defenseTotal) * 100, 100), 0))} />
            </div>
            <div className="health">
            <CircleProgressBar progress={Math.round(Math.max(Math.min((health / healthTotal) * 100, 100), 0))} />
            </div>
            <p className="value gladiator-arena mv0">
                <animated.span className={`life-value mv0 white`}>
                    {lifeSpring?.to((x: number) => Math.floor(x))}
                </animated.span>
            </p>
        </div>
    )
}

export default Power

interface CircleProgressBarProps {
    progress: number;
    strokeWidth?: number;
    size?: number;
    startingPoint?: 'top' | 'right' | 'bottom' | 'left';
}

const CircleProgressBar: React.FC<CircleProgressBarProps> = ({
    progress,
    strokeWidth = 10,
    size = 100,
    startingPoint = 'top',
}) => {
    const radius = (size - strokeWidth) / 2;
    const circumference = 2 * Math.PI * radius;
    let offset = parseFloat((circumference - (progress / 100) * circumference).toFixed(2));
    offset = isNaN(offset) ? 0 : offset
    const progressRef = useRef<SVGCircleElement>(null);

    useEffect(() => {
        if (progressRef.current) {
            progressRef.current.style.setProperty('--offset', `${offset}`);
        }
    }, [offset]);

    let rotation;
    switch (startingPoint) {
        case 'top':
            rotation = 'rotate(-90 50 50)';
            break;
        case 'right':
            rotation = 'rotate(0 50 50)';
            break;
        case 'bottom':
            rotation = 'rotate(90 50 50)';
            break;
        case 'left':
            rotation = 'rotate(180 50 50)';
            break;
        default:
            rotation = 'rotate(-90 50 50)';
            break;
    }

    return (
        <svg
            width={size}
            height={size}
            viewBox={`0 0 ${size} ${size}`}
            className="circle-progress-bar"
        >
            <circle
                cx={size / 2}
                cy={size / 2}
                r={radius}
                fill="none"
                stroke="currentColor"
                strokeWidth={strokeWidth}
                className="track"
            />
            <circle
                ref={progressRef}
                cx={size / 2}
                cy={size / 2}
                r={radius}
                fill="none"
                stroke="currentColor"
                strokeWidth={strokeWidth}
                strokeDasharray={circumference}
                strokeDashoffset={offset}
                transform={rotation}
                className="progress"
            />
        </svg>
    );
};
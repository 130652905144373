// Config
import { CollectionSlug } from '../config/collections';

// React
import { useContext, useEffect } from 'react';
import { useParams } from 'react-router';
import { UserContext } from '../Context';

// Ionic
import { IonButton, IonContent, IonIcon, IonPage, useIonViewDidEnter } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';

// Interfaces
import { UserState } from '../interfaces/User.interface';

// Components
import PlayerCardDisplay from '../components/play/PlayerCardDisplay';
import CollectionButton from './CollectionButton';

// Utils
import { tilt } from '../Utils';

// Images
import { ReactComponent as WebsiteIcon} from './../images/icons/icon-website.svg';
import { ReactComponent as XLogo } from './../images/logos/logo-x.svg';
import { ReactComponent as OpenseaLogo } from './../images/logos/logo-opensea.svg';
import { ReactComponent as RaribleLogo } from './../images/logos/logo-rarible.svg';


const Collection: React.FC = () => {

    const { router, collections, user } = useContext<UserState>(UserContext);  
    const { collection } = useParams<{ collection: CollectionSlug }>();
    const [ collectionDetails ] = collections.filter((c) => c.slug === collection);

    useIonViewDidEnter(() => {
        tilt('tilt-js-collection');
    });

    useEffect(() => {
        setTimeout(() => {
            tilt('tilt-js-collection');
            // Smooth scroll to the top of the '.collection-content' element
            const collectionContent = document.querySelector('.collection-content');
            collectionContent?.scrollTo({ top: 0, behavior: 'smooth' });
        }, 250);
    }, [collection])

    return (
        <IonPage className="gladiator-page collection">
            <IonContent fullscreen className='transparent'>
                <div className="w-100 h-100 relative z-4">
                    <div className="page-content">
                        <div className="relative row full-width">
                            <div className="column small-3 animate__animated animate__fadeIn tl">
                                {collection ? (
                                <IonButton className="back-button" onClick={() => router?.goBack()} color="primary" fill="clear">
                                    <IonIcon slot="icon-only" icon={arrowBack} color='dark' />
                                    <span className="button-text f5 ml2 text-drop-shadow">Collections
                                    </span>
                                </IonButton>
                                ) : null}
                            </div>
                            <div className="column small-6 tc">
                                {collection ? (
                                    <h2 className='gladiator-arena animate__animated animate__fadeIn text-drop-shadow'>{collectionDetails ? collectionDetails?.name : 'Your Collection'}</h2>
                                ) : (
                                    <h2 className='gladiator-arena animate__animated animate__fadeIn text-drop-shadow'>Your Collection</h2>
                                )}
                            </div>
                            <div className="column small-3 flex justify-end items-center animate__animated animate__fadeIn">
                                <div className="links flex justify-center justify-end-l items-center text-drop-shadow">
                                    {collectionDetails?.website ? (
                                        <a href={`${collectionDetails?.website}`} target="_blank" rel="noopener noreferrer">
                                            <WebsiteIcon className="website-icon" />
                                        </a>
                                    ) : null}
                                    {collectionDetails?.twitter ? (
                                        <a href={`${collectionDetails?.twitter}`} target="_blank" rel="noopener noreferrer">
                                            <XLogo className="x-logo" />
                                        </a>
                                    ) : null}
                                    {collectionDetails?.opensea ? (
                                        <a href={`${collectionDetails?.opensea}`} target="_blank" rel="noopener noreferrer">
                                            <OpenseaLogo className="opensea-logo" />
                                        </a>                                    
                                    ) : null}                                    
                                    {collectionDetails?.rarible ? (
                                        <a href={`${collectionDetails?.rarible}`} target="_blank" rel="noopener noreferrer">
                                            <RaribleLogo className="rarible-logo" />
                                        </a>
                                    ) : null}
                                </div>
                            </div>
                        </div>

                        <div className="relative row scrollable-page-content">
                            <div className="gladiator-top-border" />
                            <div className="gladiator-bottom-border" />
                            <div className="column small-12 relative z-4 flex flex-wrap justify-center pb6">

                                {/* Ion fab buttons for collection options */}
                                {!collection ? (
                                    <div className="collection-options w-100 flex flex-wrap justify-center">
                                        {collections.map((c, i) => {
                                            return (
                                                <CollectionButton 
                                                    key={`collection-button-${i}`}
                                                    collection={c}
                                                    selected={collection}
                                                    setCollection={() => {
                                                        setTimeout(() => {
                                                            router?.push(`/collection/${c.slug}`);
                                                        }, 350)
                                                    }}
                                                />
                                            )
                                        })}
                                    </div>
                                ) : null}

                                {/* Collection Banner */}
                                {/* {collection && collectionDetails ? (
                                    <div className="db w-100 flex justify-center items-center animate__animated animate__fadeIn">
                                        <div className="inner-block relative w-90 flex justify-center items-center mh2 mh5-l"
                                            style={{
                                                backgroundImage: `url('${collectionDetails?.bgImage}')`,
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center',
                                            }}
                                        >
                                            <div className="border-accent outer z-0"></div>
                                            {collectionDetails.logo ? (
                                                <img src={collectionDetails?.logo} alt="" className='logo mv4' style={{ width: '300px', height: '250px', objectFit: 'contain' }} />
                                            ): (
                                                <h2 className="logo gladiator-arena f4">{collectionDetails?.name}</h2>
                                            )}
                                        </div>
                                    </div>
                                ) : null} */}

                                <div className="db w-100 flex flex-wrap justify-center">

                                    {/* Collection Cards */}
                                    {collection && user?.collectibles.filter(c => c.collection === collection).map((m, i) => {
                                        return (
                                            <div
                                                key={`collectible-${i}`}
                                                className='player-card-container pa3 pointer'
                                            >
                                                <PlayerCardDisplay card={m} click={() => {
                                                    router?.push(`/collection/${m.collection}/${m.token_id}`)
                                                }} />
                                            </div>
                                        )
                                    })}

                                    {/* No Collection Message */}
                                    {collection && !user?.collectibles.filter(c => c.collection === collection).length ? (
                                        <div className="w-100 tc animate__animated animate__zoomIn">
                                            <h3 className='gladiator-arena'>You don't have any {collectionDetails?.name} yet!</h3>
                                            <IonButton color={'primary'} onClick={() => {
                                                window.open(`${collectionDetails?.opensea}`, '_blank');
                                            }}>
                                                <span className="dib gladiator-arena flex items-center">
                                                    <span className="mt1 pt2 mr2">Get {collectionDetails?.name}</span>
                                                    <OpenseaLogo className="opensea-logo" style={{ width: '20px', height: '20px' }} />
                                                </span>
                                            </IonButton>
                                        </div>
                                    ) : null}

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div id="tilt-js-collection" />
            </IonContent>
        </IonPage>
    );
};

export default Collection;

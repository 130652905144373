// React
import React, { useContext } from 'react';

// Styles
import './WaitModal.scss';

// Version
import { IonBadge, IonModal } from '@ionic/react';
import { UserState } from '../../interfaces/User.interface';
import { UserContext } from '../../Context';

// Images
import gladiatorCoin from '../../images/gladiator/gladiator-coin.png';

// Components
import { useSpring, animated } from 'react-spring';

const WaitModal: React.FC = () => {

    const { waitModal, setWaitModal, collectiblesLoaded, displayCollectiblesLoaded } = useContext<UserState>(UserContext);

    // Create an animation spring that tracks the collectiblesLoaded value
    const { number } = useSpring({
        number: collectiblesLoaded,
        from: { number: 1 },
    });

    return (
        <IonModal
            className="wait-modal"
            isOpen={waitModal}
            onDidDismiss={() => setWaitModal(false)}
            showBackdrop={true}
            backdropDismiss={false}
        >
                <div className="relative h-100">
                    <span className="loader-container">
                        <div className="waiting-loader z-3">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </span>
                    <img src={gladiatorCoin} alt="waiting" className='coin' />
                    {displayCollectiblesLoaded ? (
                        <p className='mv0 token-count tc'>
                            <span className="currency-amount">
                                <IonBadge>
                                    {/* Use animated.span to display the animated percentage */}
                                    <animated.span className="number f4">
                                        {number.to(n => `${n.toFixed(0)}%`)}
                                    </animated.span>
                                </IonBadge>
                            </span>
                        </p>
                    ) : null}
                </div>
        </IonModal>
    );
};

export default WaitModal;




import React, { useState, useEffect } from 'react';
import './DigitalClock.scss';
import empty from './../../images/energy-assets/energy-slot-empty.png';
import full from './../../images/energy-assets/energy-slot-full.png';

interface Props {
    energyValue: number | undefined;
}

const Energy: React.FC<Props> = ({ energyValue }) => {

    const [energy, setEnergy] = useState(energyValue || 0);
    const [displayedEnergy, setDisplayedEnergy] = useState(energy);

    useEffect(() => {
        if (energyValue !== undefined && energyValue >= 0) {
            setEnergy(energyValue);
        }
    }, [energyValue]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (displayedEnergy < energy) {
                setDisplayedEnergy(prevEnergy => prevEnergy + 1);
            } else if (displayedEnergy > energy) {
                setDisplayedEnergy(prevEnergy => prevEnergy - 1);
            } else {
                clearInterval(interval);
            }
        }, 150); // Adjust this value for faster/slower animation

        return () => clearInterval(interval);
    }, [energy, displayedEnergy]);

    return (<>
        <div className="energy-container row">
            {Array.from({ length: 9 }).map((_, index) => (
                <React.Fragment key={`${index}-${displayedEnergy}`}>
                    {(displayedEnergy <= index) ? (
                        <div className='column ma0 pa0 small-4'>
                            <img src={empty} className="empty" alt='Energy Slot Empty' />
                        </div>
                    ) : (
                        <div className='column ma0 pa0 small-4'>
                            <img src={full} className="full" alt='Energy Slot Full' />
                        </div>
                    )}
                </React.Fragment>
            ))}
        </div>
    </>);
}

export default Energy;

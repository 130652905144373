// React
import React, { useState } from 'react';

// Ionic
import { IonLabel, IonPopover, IonSegment, IonSegmentButton } from '@ionic/react';

// Interfaces
import { ReleaseNote, Credits } from '../interfaces/ReleaseNotes.interface';

// Styles
import './ReleaseNotes.scss';

// Utils
import moment from 'moment-timezone';

// Data
import releaseNotesJson from '../data/release-notes.json';
import teamJson from '../data/team.json';
import thanksJson from '../data/thanks.json';

const team: Credits[] = teamJson;

const thanks: Credits[] = thanksJson;

const releaseNotes: ReleaseNote[] = releaseNotesJson;

type SegmentType = 'release-notes' | 'credits';

const ReleaseNotes: React.FC = () => {

    const [view, setView] = useState<SegmentType>('release-notes');
    const [showPopover, setShowPopover] = useState(false);
    const [popoverEvent, setPopoverEvent] = useState<any>();
    const [popoverContent, setPopoverContent] = useState<JSX.Element>();

    /**
     * Sort release notes by date (newest at top)
     */
    const sortedNotes = [...releaseNotes].sort((a, b) => {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
    });

    return <div className="release-notes-modal-content">

        <div className="segment-container ma3">
            <IonSegment value={view} mode='ios' onIonChange={(e) => e.detail.value ? setView(e.detail.value as SegmentType) : null }>
                <IonSegmentButton value="release-notes">
                <IonLabel><span className="gladiator-arena mt2 dib">Release Notes</span></IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="credits">
                <IonLabel><span className="gladiator-arena mt2 dib">Credits</span></IonLabel>
                </IonSegmentButton>
            </IonSegment>
        </div>

        {view === 'credits' && 
        <div className="ma3 pa2 mb3 tc animate__animated animate__fadeIn">
            <p className="ma0 pv2 ph3 lh-copy f6">
                <span className="b gladiator-arena">Devs:</span><br />{team.map(member => {
                    return `${member.name}`
                }).join(' • ')}
            </p>
            <p className="ma0 pv2 ph3 lh-copy f6">
                <span className="b gladiator-arena">Special Thanks:</span><br />
                {thanks.map((member, index) => {
                    return (<button
                            className="underline-hover pointer dib ma0 pa0 ws-normal bg-transparent relative"
                            key={index}
                            onClick={e => {
                                e.persist();
                                setPopoverEvent(e);
                                setPopoverContent(<><span className="gladiator-arena gladiator-gold lh-title">{member.name}</span><br /><span className="f6 i">{member.role}</span></>);
                                setShowPopover(true);
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                        ><span className="white">{member.name}</span></button>)
                }).reduce((prev, curr, index) => <>{prev}&nbsp;&bull; {curr}</>)}
            </p>
            <IonPopover
                    mode='ios'
                    side='top'
                    alignment='center'
                    isOpen={showPopover}
                    event={popoverEvent}
                    onDidDismiss={() => setShowPopover(false)}
                    onMouseLeave={() => setShowPopover(false)}
                >
                <p className='ph3 tc mt2 mb2 pv1 lh-copy'>{popoverContent}</p>
            </IonPopover>
        </div>
        }
        {view === 'release-notes' &&
        <div className="animate__animated animate__fadeIn">
            {sortedNotes.map((note, i) => {
                return (
                    <div key={`release_note_${note.date}_${i}`} className="ma3 pa2 ba b--white-10 mb3 shadow-5-black">
                        <div className="flex justify-between items-center">
                            <h3 className='mb2 pl3 f4'>{note.version}</h3>
                            <p className="ma0 pa0 pr3">
                                {moment(note.date).format('MMM Do, YYYY')}<br />
                            </p>
                        </div>
                        {note.notes.map((note, i) => {  
                            return <p key={`release_note_p_${i}`} className='pl3 lh-copy'>&bull; {note}</p>
                        })}
                    </div>
                )
            })}
        </div>
        }
    </div>;
};

export default ReleaseNotes;

import { TokenMetadata } from "../generated/TokenMetadata";

export function evade(target: TokenMetadata) {
    return new Promise<boolean>((resolve) => {
        setTimeout(() => {
            const targetCard = document.getElementById(`card_${target.collection}_${target.token_id}`);
            // Temporarily add evade class to target card
            targetCard?.classList.add('evade');
            setTimeout(() => {
                // Remove evade class from target card
                targetCard?.classList.remove('evade');
                resolve(true);
            }, 1000)
        }, 250)
    });
}
// 
// THIS FILE HAS BEEN GENERATED AUTOMATICALLY
// DO NOT CHANGE IT MANUALLY UNLESS YOU KNOW WHAT YOU'RE DOING
// 
// GENERATED USING @colyseus/schema 2.0.25
// 

import { Schema, type, ArraySchema, MapSchema, SetSchema, DataChange } from '@colyseus/schema';
import { TokenAttributes } from './TokenAttributes'
import { Stats } from './Stats'
import { ShopItem } from './ShopItem'

export class TokenMetadata extends Schema {
    @type("string") public id!: string;
    @type("number") public token_id!: number;
    @type("string") public name!: string;
    @type("string") public description!: string;
    @type("string") public image!: string;
    @type([ TokenAttributes ]) public attributes: ArraySchema<TokenAttributes> = new ArraySchema<TokenAttributes>();
    @type(Stats) public stats: Stats = new Stats();
    @type("boolean") public flipped!: boolean;
    @type("boolean") public dead!: boolean;
    @type("string") public collection!: string;
    @type(ShopItem) public frame: ShopItem = new ShopItem();
    @type(ShopItem) public wearable: ShopItem = new ShopItem();
}

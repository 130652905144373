import React from 'react';
import './RankBar.scss';

interface RankBarProps {
  xp: number;
}

const xpLevels = [
  { rank: "Novice", range: [0, 999] },
  { rank: "Apprentice", range: [1000, 4999] },
  { rank: "Gladiator", range: [5000, 9999] },
  { rank: "Champion", range: [10000, 19999] },
  { rank: "Veteran", range: [20000, 29999] },
  { rank: "Elite", range: [30000, 39999] },
  { rank: "Master", range: [40000, 49999] },
  { rank: "Grandmaster", range: [50000, 74999] },
  { rank: "Legendary", range: [75000, 99999] },
  { rank: "Immortal", range: [100000, Infinity] }
];

const calculateMarkerPosition = (xp: number) => {
  let rankIndex = 0;
  let minXP = 0, maxXP = 0;

  // Determine the player's rank based on XP
  for (let i = 0; i < xpLevels.length; i++) {
    const [min, max] = xpLevels[i].range;
    if (xp >= min && xp <= max) {
      rankIndex = i;
      minXP = min;
      maxXP = max;
      break;
    }
  }

  // Calculate the percentage of progress within the rank
  const percentageInRank = ((xp - minXP) / (maxXP - minXP)) * 100;

  // Calculate the total percentage position across all ranks
  const totalRanks = xpLevels.length - 1;
  const percentageAcrossRanks = (rankIndex / totalRanks) * 100;
  const position = percentageAcrossRanks + (percentageInRank / totalRanks);

  return {
    percentage: position,
    rankIndex
  };
};

const RankBar: React.FC<RankBarProps> = ({ xp }) => {
  const { percentage } = calculateMarkerPosition(xp);
  let leftPositionString = `calc(${percentage}% - 1rem)`;
  // Override the left position if the marker is too close to the edge
  if (percentage < 1) {
    leftPositionString = '0';
  }
  return (
    <div className="rank-bar">
      <div
        className="rank-marker"
        style={{ left: `${leftPositionString}` }} // Adjust for marker width
      ></div>
      <div className="rank-labels">
        {xpLevels.map((level, index) => (
          <span key={index} className='pointer pa2 tc dib'>{index + 1}<span className="show-for-sr hover-label">{index + 1}: {level.rank}</span></span>
        ))}
      </div>
    </div>
  );
};

export default RankBar;

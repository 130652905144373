export enum ShopItemCurrencies {
    Ethereum = 'ETH',
    Chainlink = 'LINK',
    USDC = 'USDC',
    Gladiiator = 'GLADII',
    Paypal = 'Paypal'
}

export interface ShopItemPayload {
    wallet: string;
    itemID: number;
    transactionHash: string;
    testnet: boolean;
    currency: ShopItemCurrencies;
    amount: number;
}

export interface ShopItemMetadata {
    attack_boost?: number;
    health_boost?: number;
    defense_boost?: number;
    evasive_boost?: number;
}
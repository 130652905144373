// React
import React, { useContext, useEffect, useState } from 'react'

// Types
import { BackdropTypes } from '../../types/Backdrops.type'

// Interfaces
import { BackdropData } from '../../interfaces/Backdrop.interface';

// Styles
import './Backdrop.scss';

// Utils
import { parallaxEffect } from '../../Utils';
import { UserContext } from '../../Context';
import { CollectionDetails } from '../../config/collections.interface';

interface Props {
    type: BackdropTypes;
}

const CollectionBackdrops = (collections: CollectionDetails[]): BackdropData[] => {
    const backdrops: BackdropData[] = []
    for (const collection of collections) {
        if (collection.enabled && collection.backdrops?.length) {
            backdrops.push(...collection.backdrops)
        }
    }
    return backdrops
}

const Backdrop: React.FC<Props> = ({ type }) => {

    const { collections } = useContext(UserContext);
    const [backdrop, setBackdrop] = useState<BackdropData | undefined>()
    const [background, setBackground] = useState<string>()
    const [midground, setMidground] = useState<string>()
    const [foreground, setForeground] = useState<string>()
    const [displayBG, setDisplayBG] = useState<boolean>(false)

    useEffect(() => {
        if (collections.length) {
            setBackdrop(CollectionBackdrops(collections).find(b => b.slug === type))
        }
    }, [collections, type])

    useEffect(() => {

        if (backdrop) {  

            setBackground(backdrop.background);
            setMidground(backdrop.midground);
            setForeground(backdrop.foreground);

            // Wait a moment to reveal scene
            setTimeout(() => {
                setTimeout(() => {

                    // Background
                    parallaxEffect(
                        'scene-background',
                        'move.base',
                        'paths',
                        0.02,
                        0.001,
                        true,
                        20000,
                    );

                    // Midground
                    parallaxEffect(
                        'scene-background',
                        'move.mid',
                        'paths',
                        0.035,
                        0.01,
                        true,
                        20000,
                    );

                    // Foreground
                    parallaxEffect(
                        'scene-background',
                        'move.front',
                        'paths',
                        0.075,
                        0.02,
                        true,
                        20000,
                    );
                    setDisplayBG(true)

                }, 100)
            }, 500);

        }

    }, [backdrop])

    return (<>
        {background && midground && foreground ? (
            <div className={`scene-background animate__animated ${displayBG ? `animate__fadeIn` : `o-0`} fade-duration-3s`}>

                <div className="dim-backdrop" />
            
                <div className="move base">
                    <div className="paths">
                        <img src={background} alt="" />
                    </div>
                </div>
                <div className="move mid">
                    <div className="paths">
                        <img src={midground} alt="" />
                    </div>
                </div>
                <div className="move front">
                    <div className="paths">
                        <img src={foreground} alt="" />
                    </div>
                </div>

            </div>
        ) : null}
    </>
    )
}

export default Backdrop
// Env
import { envmode, externalRequest } from "../Env";

// Interfaces
import User from "../interfaces/User.interface";
import { ShopItemPayload } from "../interfaces/ShopItem.interface";

// Colyseus
import * as Colyseus from "colyseus.js";
import { TokenMetadata } from "../generated/TokenMetadata";
import { Ability } from "../generated/Ability";

// Utils
import { compressData } from "../Utils";

// Domain
const domain = `https://colosseum.brokenreality.com`;

/**
 * Connect to Socket Server
 * @param wallet User's wallet address 
 * @returns Promise<Socket>
 */
export const connectToGameServer = async (wallet: string): Promise<Colyseus.Client> => {
    console.log(`Connecting... ${wallet} to game server`);
    return new Promise<Colyseus.Client>((resolve, reject) => {
        try {
            const client = new Colyseus.Client(envmode === 'dev' ? 'http://localhost:8888' : domain);
            console.log(`Connected to game server`, client);
            resolve(client);
        } catch (error) {
            console.log(error);
            reject(error);
        }
    });
}

/**
 * Get available rooms
 * @param user User object
 * @returns Promise<RoomAvailable[]>
 */
export const getRooms = async (user: User) => {
    return new Promise<Colyseus.RoomAvailable[]>((resolve, reject) => {
        if (user.socket) {
            user.socket.getAvailableRooms('battle').then((rooms: Colyseus.RoomAvailable[]) => {
                // Only return only rooms with singlePlayer = false
                const allRooms = rooms.filter(room => room.metadata.singlePlayer === false);
                resolve(allRooms)
            });
        }
    });
}

/**
 * Create a new room
 * @param user User object
 * @param roomName Room Name
 * @returns Promise<Room>
 */
export const createRoom = async (user: User, roomName: string, singlePlayer?: boolean) => {
    return new Promise<Colyseus.Room>((resolve, reject) => {
        console.log('Creating room', roomName)
        console.log('Single Player', user.socket)
        if (user.socket && roomName) {
            user.socket.create(roomName, {
                wallet: user.wallet,
                singlePlayer: singlePlayer || false
            }).then(room => {
                resolve(room)
            }).catch((e) => {
                reject(e)
            });
        }
    });
}

/**
 * Join a room
 * @param user User object
 * @param room Room object
 * @returns Promise<Room>
 */
export const join = async (user: User, room: Colyseus.RoomAvailable) => {
    return new Promise<Colyseus.Room>((resolve, reject) => {
        if (user.socket) {
            console.log('Joining room', room.roomId)
            user.socket.joinById(room.roomId, {
                wallet: user.wallet
            }).then(room => {
                resolve(room)
            }).catch((e) => {
                reject(e)
            });
        }
    });
}

/**
 * Set tokens to begin
 * @param user User object
 * @param room Room object
 * @param tokens Tokens Selected
 */
export const ready = async (user: User, room: Colyseus.Room, tokens: TokenMetadata[]) => {
    try {
        const message = {
            action: 'ready',
            tokens: tokens.map(token => {
                return {
                    id: token.token_id,
                    collection: token.collection,
                    frame: token.frame.id,
                    wearable: token.wearable.id,
                }
            }),
            wallet: user.wallet
        };

        console.log('ready message', message)

        const data = compressData(JSON.stringify(message))
        room.send('ready', data);
    } catch (error) {
        console.error(error)
    }
}

/**
 * End turn
 * @param user User object
 * @param room Room object
 */
export const endTurn = async (room: Colyseus.Room) => {
    try {
        room.send('endTurn');
    } catch (error) {
        console.error(error)
    }
}

/**
 * Hover token
 * @param user User object
 * @param room Room object
 * @param token Token Hovered
 */
export const hover = async (user: User, room: Colyseus.Room, token: TokenMetadata | null) => {
    try {
        const message = {
            token,
            wallet: user.wallet
        };
        const data = compressData(JSON.stringify(message))
        room.send('hover', data);
    } catch (error) {
        console.error(error)
    }
}

/**
 * Click ability
 * @param user User object
 * @param room Room object
 * @param token Token Hovered
 * @param ability Ability
 */
export const click = async (user: User, room: Colyseus.Room, ability: Ability, token: TokenMetadata) => {
    try {
        const message = {
            ability,
            token,
            wallet: user.wallet
        };
        const data = compressData(JSON.stringify(message))
        room.send('click', data);
    } catch (error) {
        console.error(error)
    }
}

/**
 * Leave room
 * @param user User object 
 * @param room Room object
 * @returns Promise<boolean>
 */
export const leave = async (user: User, room: Colyseus.Room) => {
    return new Promise<boolean>(resolve => {
        if (user.socket) {
            room.leave();
            resolve(true)
        }
    });
}

/**
 * Rematch request
 * @param user User object
 * @param room Room object
 */
export const rematch = async (user: User, room: Colyseus.Room) => {
    try {
        const message = {
            wallet: user.wallet
        };
        const data = compressData(JSON.stringify(message))
        room.send('rematchRequest', data);
    } catch (error) {
        console.error(error)
    }
}

/**
 * Rematch accepted
 * @param user User object
 * @param room Room object
 */
export const rematchAccepted = async (user: User, room: Colyseus.Room) => {
    try {
        const message = {
            wallet: user.wallet
        };
        const data = compressData(JSON.stringify(message))
        room.send('rematchAccepted', data);
    } catch (error) {
        console.error(error)
    }
}

/**
 * Rematch declined
 * @param user User object
 * @param room Room object
 */
export const rematchDecline = async (user: User, room: Colyseus.Room) => {
    try {
        const message = {
            wallet: user.wallet
        };
        const data = compressData(JSON.stringify(message))
        room.send('rematchDeclined', data);
    } catch (error) {
        console.error(error)
    }
}

/**
 * Card ability
 * @param user User object
 * @param room Room object
 * @param ability Ability
 * @param source Source Token
 * @param target Target Token
 */
export const ability = async (user: User, room: Colyseus.Room, ability: Ability, source: TokenMetadata, target: TokenMetadata | TokenMetadata[]) => {
    try {
        const message = {
            wallet: user.wallet,
            ability,
            source,
            target
        };
        const data = compressData(JSON.stringify(message))
        room.send('ability', data);
    } catch (error) {
        console.error(error)
    }
}

/**
 * Purchase Shop Item
 * @param shopItemPayload Shop Item Payload
 * @returns ShopItemPayload
 */
export const purchaseShopItem = async (shopItemPayload: ShopItemPayload) => {
    const path = `${envmode === 'dev' ? 'http://localhost:8888' : domain}/purchase-shop-item`;
    return new Promise<any>(async (resolve, reject) => {
        try {
            const res = await externalRequest(path).post(path, shopItemPayload)
            resolve(res.data);
        } catch (error) {
            reject(error);
        }
    });
}

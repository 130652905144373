// React
import React, { useMemo, useState } from 'react';

// Version
import * as gitData from '../../data/latest-tag.json';

const Version: React.FC = () => {
    const [version, setVersion] = useState<string>();

    const git: any = gitData;

    useMemo(() => {
        setVersion(`${git?.default?.tag}`);
        // console.log(`Gladiator ${git?.default?.tag}`);
    }, [git]);

    return <>{version}</>;
};

export default Version;

import React, { useEffect } from 'react';

interface SoundProps {
  src: string;
  volume: number;
}

const Sound: React.FC<SoundProps> = ({ src, volume }) => {
  useEffect(() => {
    const audio = new Audio(src);
    audio.volume = volume;
    audio.play();
  }, [src, volume]);
  return null;
};

export default Sound;

import { ShopItemRow, ShopItemInsert, CollectionRow, CollectionInsert, BackgroundInsert, BackgroundRow } from './types';
import { supabase } from './api';

/**
 * Create Collection
 * @param payload 
 * @returns CollectionRow
 */
export const createCollection = async (payload: CollectionInsert) => {
    return new Promise<CollectionRow>((resolve, reject) => {
        try {
            supabase.from('collections').insert(payload).single()
                .then(({ data, error }) => {
                    if (error) {
                        console.error(`createCollection`, error);
                        reject(error);
                    } else {
                        console.log(`createCollection`, data);
                        resolve(data as any);
                    }
                });
        } catch (error) {
            console.error(`createCollection`, error);
            reject(error);
        }
    });
}

/**
 * Update Collection
 * @param payload 
 * @returns CollectionRow
 */
export const updateCollection = async (payload: CollectionRow) => {
    return new Promise<CollectionRow>((resolve, reject) => {
        try {
            supabase.from('collections').update(payload).eq('uuid', payload.uuid).single()
                .then(({ data, error }) => {
                    if (error) {
                        console.error(`updateCollection`, error);
                        reject(error);
                    } else {
                        console.log(`updateCollection`, data);
                        resolve(data as any);
                    }
                });
        } catch (error) {
            console.error(`updateCollection`, error);
            reject(error);
        }
    });
}


/**
 * Create Shop Item
 * @param payload 
 * @returns ShopItemRow
 */
export const createShopItem = async (payload: ShopItemInsert) => {
    return new Promise<ShopItemRow>((resolve, reject) => {
        try {
            supabase.from('items').insert(payload).single()
                .then(({ data }) => {
                    console.log(`createShopItem`, data);
                    resolve(data as any);
                })
        } catch (error) {
            console.error(`createShopItem`, error);
            reject(error);
        }
    })
}

/**
 * Update Shop Item
 * @param payload 
 * @returns ShopItemRow
 */
export const updateShopItem = async (payload: ShopItemRow) => {
    return new Promise<ShopItemRow>((resolve, reject) => {
        try {
            supabase.from('items').update(payload).eq('id', payload.id).single()
                .then(({ data }) => {
                    console.log(`updateShopItem`, data);
                    resolve(data as any);
                })
        } catch (error) {
            console.error(`updateShopItem`, error);
            reject(error);
        }
    })
}

/**
 * Create Background
 * @param payload 
 * @returns BackgroundRow
 */
export const createBackground = async (payload: BackgroundInsert) => {
    return new Promise<BackgroundRow>((resolve, reject) => {
        try {
            supabase.from('backgrounds').insert(payload).single()
                .then(({ data, error }) => {
                    if (error) {
                        console.error(`createBackground`, error);
                        reject(error);
                    } else {
                        console.log(`createBackground`, data);
                        resolve(data as any);
                    }
                });
        } catch (error) {
            console.error(`createBackground`, error);
            reject(error);
        }
    });
}

/**
 * Update Background
 * @param payload 
 * @returns BackgroundRow
 */
export const updateBackground = async (payload: BackgroundRow) => {
    return new Promise<BackgroundRow>((resolve, reject) => {
        try {
            supabase.from('backgrounds').update(payload).eq('id', payload.id).single()
                .then(({ data, error }) => {
                    if (error) {
                        console.error(`updateBackground`, error);
                        reject(error);
                    } else {
                        console.log(`updateBackground`, data);
                        resolve(data as any);
                    }
                });
        } catch (error) {
            console.error(`updateBackground`, error);
            reject(error);
        }
    });
}

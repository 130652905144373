// React
import React, { useContext } from 'react';
import { UserContext } from '../../Context';

// Ionic
import { IonButton, IonIcon } from '@ionic/react';
import { eyeOutline } from 'ionicons/icons';

// Gladiator Images
import cards from './../../images/home/spacecards.png';

// Components
import ParticleOverlay from './ParticleOverlay';
import Version from './Version';

const WelcomeBlock: React.FC = () => {
    const { collections, theme, setChooseTheme } = useContext(UserContext);

    // Find the collection that matches the current theme
    const currentCollection = collections.find(c => c.slug === theme);

    return (
        <div className='welcome-block'>
        {!theme ? (
            <div className="gladiator-welcome-container">
                <div className={`w-100 mt3 flex items-center welcome-content-container relative`}>
                    <div className="relative ma0 pa0 row welcome-content z-1 animate__animated animate__zoomIn">
                        <ParticleOverlay />
                        <img src={cards} alt="" className='cards' />
                        <h1 className='title pt-serif fw6 f3'><Version /></h1>
                        {/* <div className="change-theme-btn flex justify-center items-center">
                            <IonButton color={'primary'} onClick={() => setChooseTheme(true)} className='change-theme-button'>
                            <span className="button-text">Change Theme</span> <IonIcon slot="end" icon={eyeOutline} color='dark'></IonIcon> 
                            </IonButton>
                        </div> */}
                    </div>
                </div>
            </div>
        ) : null}
        {theme && currentCollection ? (currentCollection?.welcomeComponent) : null}
        </div>
    );
};

export default WelcomeBlock;
// Config
import { CollectionDetails } from "../config/collections.interface";

// React
import { useContext } from "react";
import { UserState } from "../interfaces/User.interface";
import { UserContext } from "../Context";
import { IonBadge, IonButton } from "@ionic/react";

interface CollectionButtonProps {
    collection: CollectionDetails;
    selected: CollectionDetails | null;
    themeButton?: boolean;
    setCollection: () => void;
}

const CollectionButton: React.FC<CollectionButtonProps> = ({ collection, selected, themeButton, setCollection }) => {

    const { user } = useContext<UserState>(UserContext);  

    return (
        <div className={`collection-button-container flex justify-center ${themeButton ? 'theme-button w-100' : ''}`}>
            <IonButton
                mode="md"
                className={`collection-button ${themeButton ? 'theme-button' : ''} animate__animated animate__zoomIn ${collection === selected ? 'selected' : ''}`}
                color={'transparent'}
                onClick={setCollection}            
            >
                    <div className="column inner-block relative"
                        style={{
                            backgroundImage: `url('${collection?.bgImage}')`
                        }}
                    >
                        <div className="border-accent outer z-0"></div>
                        {collection.logo ? (
                            <img src={collection.logo} alt="" className='logo' />
                        ): (
                            <h2 className="logo f4">{collection.name}</h2>
                        )}
                        <div className="cards"
                            data-tilt
                            data-tilt-max="12"
                            data-tilt-full-page-listening
                            data-tilt-reverse="true"
                        >
                            {collection.cardImages?.map((card, index) => (
                                <img key={`cardImage_${index}`} src={card} className={index === 0 ? 'left' : index === 1 ? 'center' : 'right'} alt="" />
                            ))}
                            <div className="count">
                                <IonBadge><span className="number pt-serif fw6">{user?.collectibles.filter(c => c.collection === collection.slug).length}</span></IonBadge>
                            </div>
                        </div>
                    </div>


            </IonButton>

        </div>
    )

}

export default CollectionButton;
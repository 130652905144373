import { ShopItemType } from "../supabase/types";

export enum EquipmentType {
    FRAMES = 'frames',
    ABILITIES = 'abilities',
    WEARABLES = 'wearables',
    COLLECTIBLES = 'collection',
};

export interface Equipment {
    id: number;
    type: ShopItemType;
    image_front: string;
    image_back?: string;
}
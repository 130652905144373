import { infuraWSS, infuraHTTP } from './../Env';
import Web3 from "web3";
import WalletLink from "@coinbase/wallet-sdk"

// Logos
import logoMetaMask from './../images/logos/logo-metamask.png';
import logoCoinbase from './../images/logos/logo-coinbase.png';
import logoFrame from './../images/logos/logo-frame.png';

// Supported networks / chains
export type chainIDs = 1 | 5

// Web3 Providers
export enum WalletProviders {
    Infura = 'Infura',
    MetaMask = 'MetaMask',
    Coinbase = 'Coinbase',
    Frame = 'Frame',
    Demo = 'Demo'
}

// Infura
export const infura = new Web3(`${infuraWSS()}`);
export const infuraHTTPProvider = new Web3(new Web3.providers.HttpProvider(`${infuraHTTP()}`));

// Browser Extension Provider
export let injectedProvider: any = window.ethereum;
export let isCoinbaseWallet = injectedProvider?.isCoinbaseWallet;
export let isWalletLink = injectedProvider?.isWalletLink;
export let isMetaMask = injectedProvider?.isMetaMask;
export let isFrame = injectedProvider?.isFrame;

// Coinbase WalletLink Provider
const walletLink = new WalletLink({
    appName: "Gladiator",
    // appLogoUrl: "",
    darkMode: true
});
const walletLinkProvider = walletLink.makeWeb3Provider(`${infuraWSS()}`, 1);

// Custom We3 Provider Interface
export interface Web3Provider { 
    name: WalletProviders;
    id: string;
    provider: any;
    connect: boolean;
    image?: string;
}

export interface Web3ProviderError {
    code: number;
    message: string;
    stack: string;
}

/**
 * Define Web3 Providers
 * A custom object used to reference an array of providers
 * @param name Name of provider
 * @param id SHA256 Encoded ID of provider
 * @param provider Web3 provider object
 */
export let Web3Providers: Web3Provider[] = [];

setTimeout(() => {

    // Define Web3 Providers
    injectedProvider = window.ethereum;
    isCoinbaseWallet = injectedProvider?.isCoinbaseWallet;
    isWalletLink = injectedProvider?.isWalletLink;
    isMetaMask = injectedProvider?.isMetaMask;
    isFrame = injectedProvider?.isFrame;    

    Web3Providers = [
        {
            name: WalletProviders.Infura,
            id: 'INF',
            provider: infura,
            connect: false
        },
        {
            name: WalletProviders.MetaMask,
            id: 'MM',
            provider: injectedProvider,
            connect: true,
            image: logoMetaMask
        },
        {
            name: WalletProviders.Coinbase,
            id: 'CB',
            provider: walletLinkProvider,
            connect: true,
            image: logoCoinbase
        },
        {
            name: WalletProviders.Frame,
            id: 'FRM',
            provider: injectedProvider,
            connect: true,
            image: logoFrame
        },
        {
            name: WalletProviders.Demo,
            id: 'DEM',
            provider: null,
            connect: false
        }
    ]

    console.log('injectedProvider', injectedProvider);
    console.log('isCoinbaseWallet', isCoinbaseWallet);
    console.log('isWalletLink', isWalletLink);
    console.log('isMetaMask', isMetaMask);
    console.log('isFrame', isFrame);
    
}, 1000)


/**
 * Return Web3 Provider by Name
 * @param name Infura, MetaMas, Coinbase
 * @returns provider object
 */
export function providerByName(name: string): Web3Provider {
    return Web3Providers.filter(provider => provider.name === name)[0];
}

/**
 * Return Web3 Provider by ID
 * @param id SHA256 Encoded ID
 * @returns provider object
 */
export function providerByID(id: string): Web3Provider {
    return Web3Providers.filter(provider => provider.id === id)[0];
}


/**
 * 
 * @param address 
 * @returns 
 */
export async function getENSName(address: string) {
    try {
        console.log('getENSName', address);
    } catch (error) {
        console.error("ENS name not found for this address", error);
        return null;
    }
}

export default Web3;
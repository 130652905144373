import { Schema } from "@colyseus/schema";
import { MuffinTraitTypes } from "../types/MuffinTraits.type";
import { Card } from "./Card.interface";
import { CollectionSlug } from "../config/collections";

export interface NFTs {
    muffins: TokenMetadata[];
    moths: TokenMetadata[];
    bedlams?: TokenMetadata[];
    mechapenguins?: TokenMetadata[];
    spaceguins?: TokenMetadata[];
    mechaapes?: TokenMetadata[];
    buns?: TokenMetadata[];
    bufficorns?: TokenMetadata[];
}

export interface TokenMetadata extends Schema {
    token_id: number;
    name: string;
    description: string;
    image: string;
    attributes: TokenAttributes[];
    stats: Card;
    flipped?: boolean;
    collection: CollectionSlug;
}

export interface TokenAttributes {
    trait_type: MuffinTraitTypes;
    value: string;
}

export interface MuffinOwnershipData {
    addr: string;
    burned: boolean;
    extraData: string;
    startTimestamp: string;
}

/**
 * Single Collectible Stats
 */
export interface NumericStats {
    collection?: string;
    token_id?: number;
    total_games: number;
    total_attacks: number;
    total_power_attack: number;
    total_heal_defense: number;
    total_power_heal: number;
    total_deaths: number;
}

export interface StringStats {
    Ability: string;
    Frame: string;
    Wearable: string;
}

export type Stats = NumericStats;

export const defaultStats: Stats = {
    total_games: 0,
    total_attacks: 0,
    total_power_attack: 0,
    total_heal_defense: 0,
    total_power_heal: 0,
    total_deaths: 0,
};
// React
import { UserContext } from '../Context';
import { useContext, useEffect } from 'react';

// Ionic
import { IonContent, IonPage } from '@ionic/react';

// Interfaces
import { UserState } from '../interfaces/User.interface';

// Styles
import './Home.scss';

// Utils
import { tilt } from '../Utils';

// Components
import Login from './Login';
import Welcome from './Welcome';

const Home: React.FC = () => {

  const { user, tokensLoaded, collectiblesLoaded } = useContext<UserState>(UserContext);

  // Initialize tilt on mount
  useEffect(() => {
    initTilt()
  }, [])

  /**
   * Initialize vanilla tilt
   */
  const initTilt = (timeout: number = 100) => {
    setTimeout(() => {
      tilt('tilt-js-welcome');
    }, timeout)
  }

  return (
    <IonPage>
      <IonContent fullscreen className='transparent'>
        {tokensLoaded && collectiblesLoaded === 100 && user.wallet ? (
          <Welcome />
        ) : (
          <Login />
        )}
        <div id="tilt-js-welcome"></div>
      </IonContent>
    </IonPage>
  );
};

export default Home;

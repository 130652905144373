import React, { useState, useEffect, useContext } from 'react';
import {
    IonButton,
    IonCheckbox,
    IonInput,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
} from '@ionic/react';
import { BackgroundRow, CollectionRow } from '../../supabase/types';
import { createBackground, updateBackground } from '../../supabase/api-admin';
import { UserContext } from '../../Context';

interface BackgroundFormProps {
    collections: CollectionRow[]; // Add collections as a prop
    existingBackground?: BackgroundRow | null;
    onSubmit: (payload: any) => void;
}

const initialFormState = {
    collection: '',
    created: '',
    deleted: false,
    name: '',
    slug: '',
};

const BackgroundForm: React.FC<BackgroundFormProps> = ({ collections, existingBackground, onSubmit }) => {

    const { setAlertHeader, setAlertMessage, setAlertModal, setWaitModal } = useContext(UserContext);

    // Form Data
    const [formState, setFormState] = useState<any>({
        ...initialFormState,
        ...existingBackground,
    });

    useEffect(() => {
        if (existingBackground) {
            setFormState({
                ...existingBackground,
            });
        } else {
            setFormState(initialFormState);
        }
    }, [existingBackground]);

    useEffect(() => {
        console.log('formState', formState);
    }, [formState]);

    /**
     * Handle form input changes
     * @param e 
     */
    const handleChange = (e: CustomEvent) => {
        const { name, value } = e.target as HTMLInputElement;
        setFormState({
            ...formState,
            [name]: value,
        });
    };

    const handleSelectChange = (e: any) => {
        const name = e.target.name;
        const value = e.detail.value;

        setFormState({
            ...formState,
            [name]: value,
        });
    };

    const handleCheckboxChange = (e: CustomEvent) => {
        const { name, checked } = e.target as HTMLInputElement;
        setFormState({
            ...formState,
            [name]: checked,
        });
    };

    /**
     * Handle form submission
     */
    const handleSubmit = () => {
        setWaitModal(true);
        const payload = {
            ...formState,
        };

        if (!existingBackground) {
            createBackground(payload).then((res) => {
                onSubmit(payload);
                setWaitModal(false);
                setAlertHeader('Success');
                setAlertMessage('Background created successfully');
                setAlertModal(true);
                setFormState(initialFormState);
            }).catch((error) => {
                setWaitModal(false);
                setAlertHeader('Error');
                setAlertMessage(error.message);
                setAlertModal(true);
            });
        } else {
            updateBackground(payload).then((res) => {
                onSubmit(payload);
                setWaitModal(false);
                setAlertHeader('Success');
                setAlertMessage('Background updated successfully');
                setAlertModal(true);
                setFormState(initialFormState);
            }).catch((error) => {
                setWaitModal(false);
                setAlertHeader('Error');
                setAlertMessage(error.message);
                setAlertModal(true);
            });
        }
    };

    return (
        <form className='admin-form'>
            <IonItem lines="full" className="ion-no-padding" mode="md">
                <IonLabel position="stacked">Collection</IonLabel>
                <IonSelect
                    name="collection"
                    value={formState.collection}
                    onIonChange={handleSelectChange}
                    interfaceOptions={{ mode: 'md' }}
                    interface='popover'
                    placeholder='Select Collection'
                >
                    {collections.map((collection) => (
                        <IonSelectOption key={collection.uuid} value={collection.uuid}>
                            {collection.name}
                        </IonSelectOption>
                    ))}
                </IonSelect>
            </IonItem>

            <IonItem lines="full" className="ion-no-padding" mode="md">
                <IonLabel position="stacked">Name</IonLabel>
                <IonInput
                    name="name"
                    value={formState.name}
                    onIonInput={handleChange}
                    placeholder="Enter background name"
                    mode="md"
                />
            </IonItem>

            <IonItem lines="full" className="ion-no-padding" mode="md">
                <IonLabel position="stacked">Slug</IonLabel>
                <IonInput
                    name="slug"
                    value={formState.slug}
                    onIonInput={handleChange}
                    placeholder="Enter background slug"
                    mode="md"
                />
            </IonItem>

            <IonItem lines="full" className="ion-no-padding" mode="md">
                <IonLabel position="stacked">Created</IonLabel>
                <IonInput
                    name="created"
                    value={formState.created}
                    onIonInput={handleChange}
                    placeholder="Enter created date"
                    mode="md"
                />
            </IonItem>

            <IonItem lines="full" className="ion-no-padding" mode="md">
                <IonLabel position="stacked">Deleted</IonLabel>
                <IonCheckbox
                    name="deleted"
                    checked={formState.deleted}
                    onIonChange={handleCheckboxChange}
                    mode="md"
                />
            </IonItem>

            <IonButton expand="block" onClick={handleSubmit} mode="md">
                {existingBackground ? 'Update Background' : 'Create Background'}
            </IonButton>
        </form>
    );
};

export default BackgroundForm;

import { LocalCollectionProperties } from "../../config/collections.interface";
import { CollectionSlug, spacesStorage } from "../../config/collections";
import WelcomeBlock from "./WelcomeBlock";
import { BackdropTypes } from "../../types/Backdrops.type";

// Collection Slug
const slug: CollectionSlug = CollectionSlug.Buns;

// Object Path
export const objectPath_buns = `${spacesStorage}/${slug}`;
// const frames = `${objectPath_buns}/shop/cardframe`;
// const wearables = `${objectPath_buns}/shop/wearable`;
const backdrops = `${objectPath_buns}/backdrops`;

const collection: LocalCollectionProperties = {
    slugLocal: CollectionSlug.Buns,
    partner: true,
    logo: `${objectPath_buns}/welcome/logo.png`,
    logoSmall: `${objectPath_buns}/welcome/logo-small.png`,
    bgImage: `${objectPath_buns}/welcome/bg.png`,
    cardImages: [
        `${objectPath_buns}/welcome/card-2.png`, 
        `${objectPath_buns}/welcome/card-1.png`, 
        `${objectPath_buns}/welcome/card-3.png`
    ],
    gameplay: {
        cardFront: `${objectPath_buns}/card/front.png`,
        cardBack: `${objectPath_buns}/card/back.png`
    },
    colosseumBackdrop: `${objectPath_buns}/backdrops/BunFields.jpg`,
    welcomeComponent: <WelcomeBlock />,
    backdrops: [
        {
            name: 'Planet Macaron',
            slug: BackdropTypes.BunTown,
            background: `${backdrops}/${BackdropTypes.BunTown}/bg.jpg`,
            midground: `${backdrops}/${BackdropTypes.BunTown}/mg.png`,
            foreground: `${backdrops}/${BackdropTypes.BunTown}/fg.png`,
        },
        {
            name: 'Bun Hills',
            slug: BackdropTypes.BunHills,
            background: `${backdrops}/${BackdropTypes.BunHills}/bg.jpg`,
            midground: `${backdrops}/${BackdropTypes.BunHills}/mg.png`,
            foreground: `${backdrops}/${BackdropTypes.BunHills}/fg.png`,
        }
    ]
};

export default collection;
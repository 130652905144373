// Config
import { CollectionSlug } from '../config/collections';

// React
import { UserContext } from '../Context';
import { useContext, useEffect, useState } from 'react';
import { PlayContext, PlayState } from './PlayContext';

// Ionic
import { IonButton, IonContent, IonIcon, IonItem, IonLabel, IonList, IonPage, IonPopover, IonSearchbar, IonSegment, IonSegmentButton, IonSpinner, useIonViewDidEnter } from '@ionic/react';
import { chevronDown } from 'ionicons/icons';

// Interfaces
import { UserState } from '../interfaces/User.interface';
import { CollectionDetails } from '../config/collections.interface';

// Images
import { ReactComponent as GetReadyPlaceholder } from '../images/get-ready-placeholder.svg';

// Components
import PlayerCardDisplay from '../components/play/PlayerCardDisplay';
import Power from '../components/play/Power';
import CardEquipment from '../components/shop/CardEquipment';
import ParticleOverlay from '../components/ui/ParticleOverlay';

// Services
import { ready } from '../services/GameServer';

// Styles
import './GetReady.scss';

// Utils
import { tilt } from '../Utils';

// Utils
import { getCollectionByID } from '../config/utils';
import { EquipmentType } from '../interfaces/Equipment.interface';

// Images
import { ReactComponent as GetReadyButtonFrame } from '../images/get-ready-btn-frame.svg';

const GetReady: React.FC = () => {

    const { collections, user, shopItems } = useContext<UserState>(UserContext);
    const { 

        // State
        room,
        requiredTokens,
        loadingToken,
        prepareToken,
        frame,
        wearable,
        myTokens,
    
        // Methods
        setLoadingToken,
        setPrepareToken,
        setFrame,
        setWearable,
        toggleTokenSelect,
        isReady,
        isEquipmentAttachedToAnyTeamMember,
        isThisEquipmentAttachedToThisToken
    
      }: PlayState = useContext(PlayContext);

    const [collection, setCollection] = useState<CollectionSlug | null>(CollectionSlug.CosmicMuffins);
    const [ collectionDetails, setCollectionDetails ] = useState<CollectionDetails | undefined>();
    const [searchText, setSearchText] = useState<string>('');
    const [equipment, setEquipment] = useState<EquipmentType>(EquipmentType.FRAMES);
    const [showPopover, setShowPopover] = useState(false);

    // const equipmentAlreadyAttachedOverlay = () => {
    //     return (
    //         <div className='already-attached animate__animated animate__fadeIn'><p className='gladiator-arena'>Already Attached</p></div>
    //     )
    // }

    const handlePopoverDismiss = () => {
        setShowPopover(false);
    };

    useIonViewDidEnter(() => {
        tilt('tilt-js-get-ready');
    });

    // Collection search listener
    useEffect(() => {

        setPrepareToken(null)
        setFrame(null)
        setWearable(null)

        // Filter the collection based on the search text
        const playerCards = document.querySelectorAll('.gladiator-page.get-ready .characters .player-card-container');
        playerCards?.forEach((card: any) => {
            const cardName = card.querySelector('.name')?.innerHTML;
            const tokenNumber = card.querySelector('.token')?.innerHTML;
            // If the card name or token number includes the search text, display the card
            if (cardName?.toLowerCase().includes(searchText.toLowerCase()) || tokenNumber?.toLowerCase().includes(searchText.toLowerCase())) {
                card.style.display = 'block';
            } else {
                card.style.display = 'none';
            }
        });

        tilt('tilt-js-get-ready');

        setCollectionDetails(collections.find((c) => c.slug === collection));

    }, [searchText, collection, setFrame, setWearable, setPrepareToken, collections]);

    // Initialize the tilt.js effect
    useEffect(() => {       
        setTimeout(() => {
            tilt('tilt-js-get-ready');
        }, 250);
    }, [prepareToken?.frame?.id, frame?.id, wearable?.id, loadingToken, myTokens, prepareToken]);

    useEffect(() => {
        // console.log('shopItems', shopItems)
        // console.log('equipment', equipment)
    }, [shopItems, equipment])

    return (
        <IonPage className="gladiator-page get-ready">
            <IonContent fullscreen className='transparent'>
                <div className="w-100 h-100 relative z-4">
                    <ParticleOverlay />
                    <div className="page-content">
                        <div className="relative row scrollable-page-content">
                            {/* <div className="gladiator-top-border mt3" /> */}
                            {/* <div className="gladiator-bottom-border" /> */}
                            <div className="column small-12 relative z-4 flex flex-wrap justify-center">

                                {/* Gladiator Cards */}
                                <div className="gladiator-cards column small-3">
                                    <div className="flex justify-between bb b--white-20 mb3">
                                        <h2 className="gladiator-arena w-50 tl mv0 text-drop-shadow">
                                            <span className="f6">Select</span><br />
                                            <span className="pt3 truncate">Cards</span>
                                        </h2>
                                        <div className='tl w-50'>
                                            <IonSearchbar
                                                autocapitalize={'false'}
                                                showClearButton="always"
                                                placeholder="Search"
                                                mode='ios'
                                                color={'transparent'}
                                                onIonInput={(e: any) => {
                                                    const searchValue = e.target.value;
                                                    setSearchText(searchValue);
                                                }}
                                            ></IonSearchbar>
                                        </div>
                                    </div>
                                    <div>
                                        <IonButton
                                            color={'transparent'}
                                            fill={'clear'}
                                            onClick={() => setShowPopover(true)}
                                            id='collection-selector'
                                        >
                                            <span className="dib pt2 mt1 gladiator-arena white">{collectionDetails?.name}</span>
                                            <IonIcon icon={chevronDown} className="ml3 white" />
                                        </IonButton>

                                        <IonPopover
                                            isOpen={showPopover}
                                            onDidDismiss={handlePopoverDismiss}
                                            trigger='collection-selector'
                                        >
                                            <IonList>
                                                {collections.map((c, i) => (
                                                    <IonItem
                                                        button
                                                        key={`opt_${i}`}
                                                        onClick={() => {
                                                            setCollection(null);
                                                            setCollection(c.slugLocal);
                                                            setTimeout(() => {
                                                                handlePopoverDismiss();
                                                            }, 100);
                                                        }}
                                                    >
                                                        <span className="pt3 db gladiator-arena">{c.name}</span>
                                                    </IonItem>
                                                ))}
                                            </IonList>
                                        </IonPopover>
                                    </div>
                                    <div className='column small-12 relative z-4 flex flex-wrap justify-center items-center characters content-start'>
                                        {user?.collectibles.filter(c => c.collection === collection).map((m, i) => {
                                            if (!isReady(m)) {
                                                return (
                                                    <div
                                                        key={`collectible-${m.token_id}-${m.collection}-${i}`}
                                                        className='player-card-container pa3 relative'
                                                    >
                                                        <PlayerCardDisplay card={m} click={() => {
                                                            if (prepareToken && prepareToken.token_id === m.token_id) {
                                                                setPrepareToken(null);
                                                            } else {
                                                                setCollection(m.collection as CollectionSlug);
                                                                setLoadingToken(true);
                                                                setPrepareToken(null);
                                                                setTimeout(() => {
                                                                    setLoadingToken(false);
                                                                    setPrepareToken(m);
                                                                }, 100)
                                                            }
                                                        }} />
                                                    </div>
                                                )
                                            } else {
                                                return null
                                            }
                                        })}
                                    </div>
                                </div>

                                {/* Gladiator Preview */}
                                <div className="gladiator-preview column small-6 tc flex flex-column">
                                    {/* <h3 className="gladiator-arena">Get Ready</h3> */}
                                    <div className="card-preview-container flex justify-center items-center relative">

                                        {/* Select character message */}
                                        {prepareToken === null && !loadingToken ? (
                                            <div className="animate__animated animate__zoomIn relative choose-wisely">
                                                <div className="relative player-card">
                                                    <GetReadyPlaceholder className='placeholder' />
                                                    <h3 className="gladiator-arena w5">Choose your combatant wisely</h3>
                                                </div>
                                            </div>
                                        ) : null}

                                        {/* Loading spinner */}
                                        {loadingToken ? (
                                            <div className="animate__animated animate__fadeIn text-drop-shadow-dark">
                                                <IonSpinner name="crescent" color={'dark'} />
                                            </div>
                                        ) : null}

                                        {/* Card Preview */}
                                        {prepareToken?.token_id ? (
                                            <div className="animate__animated animate__zoomIn">
                                                <PlayerCardDisplay 
                                                    card={prepareToken} 
                                                    clickFlip
                                                    stats
                                                />
                                            </div>
                                        ) : null}

                                    </div>
                                </div>

                                {/* Gladiator Equip */}
                                <div className="gladiator-equip column small-3 tr flex flex-column justify-start relative">

                                    <div className="equipment">
                                        {/* Equipment */}
                                        <div className="flex justify-between bb b--white-20 mb3">
                                            <h2 className="gladiator-arena w-50 tl mv0 text-drop-shadow">
                                                <span className="f6">Select</span><br />
                                                <span className="pt3 truncate">Equipment</span>
                                            </h2>
                                            <div className='tl w-50'>
                                                <IonSearchbar
                                                    autocapitalize={'false'}
                                                    showClearButton="always"
                                                    placeholder="Search equipment"
                                                    mode='ios'
                                                    color={'transparent'}
                                                ></IonSearchbar>
                                            </div>
                                        </div>
                                        <div>
                                            <IonSegment
                                                value={equipment}
                                                onIonChange={(e) => {
                                                    const value = e.detail.value as string;
                                                    const lowercaseValue = value.toLowerCase();
                                                    const equipmentType = lowercaseValue as EquipmentType;
                                                    setEquipment(equipmentType)
                                                }}
                                                mode='ios'
                                                color={'primary'}
                                            >
                                                <IonSegmentButton value={EquipmentType.FRAMES}>
                                                    <IonLabel><span className="gladiator-arena mt2 pt1 dib ttu">Frames</span></IonLabel>
                                                </IonSegmentButton>
                                                <IonSegmentButton value={EquipmentType.WEARABLES}>
                                                    <IonLabel><span className="gladiator-arena mt2 pt1 dib ttu">Wearables</span></IonLabel>
                                                </IonSegmentButton>
                                            </IonSegment>
                                        </div>
                                        <div className='column small-12 relative z-4 flex equipment pv2 mb2'>
                                            {collection ? (
                                                <>
                                                    {equipment === EquipmentType.FRAMES ? shopItems
                                                        .filter(item => item.type === EquipmentType.FRAMES)
                                                        .filter(s => getCollectionByID(s.collection!, collections).slug === collection)
                                                        .map((item, i) => {
                                                            const alreadyAttached = isEquipmentAttachedToAnyTeamMember(item) && (prepareToken && !isThisEquipmentAttachedToThisToken(prepareToken, item));
                                                            return (
                                                                <div key={`collectible-purchase-${i}`} className={`player-card-container pa3 w-30 relative ${alreadyAttached ? `o-20` : ``} `}>
                                                                    <CardEquipment shopItem={item} />
                                                                </div>
                                                            )
                                                    }) : null}
                                                    {equipment === EquipmentType.WEARABLES ? shopItems
                                                        .filter(item => item.type === EquipmentType.WEARABLES)
                                                        .filter(s => getCollectionByID(s.collection!, collections).slug === collection)
                                                        .map((item, i) => {
                                                            const alreadyAttached = isEquipmentAttachedToAnyTeamMember(item) && (prepareToken && !isThisEquipmentAttachedToThisToken(prepareToken, item));
                                                            return (
                                                                <div key={`collectible-purchase-${i}`} className={`player-card-container pa3 w-30 relative ${alreadyAttached ? `o-20` : ``} `}>
                                                                    <CardEquipment shopItem={item} />
                                                                </div>
                                                            )
                                                    }) : null}
                                                </>
                                            ) : (
                                                <div className="animate__animated animate__fadeIn mv6">
                                                    <IonSpinner name="crescent" />
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="team relative z-9 mt3">
                                        {/* Team */}
                                        <div className="flex justify-between bb b--white-20">
                                            <h2 className="gladiator-arena w-100 tl mv0 flex items-center text-drop-shadow">
                                                <span className="pt3 truncate">Your Team</span>
                                            </h2>
                                            <div>
                                                <div className="power-container-get-ready">
                                                    <Power tokens={myTokens} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`w-100 team-container team-count-${myTokens?.length}`}>
                                            {/* Loop through my tokens */}
                                            {myTokens.map((token, i) => {
                                                return (
                                                    <div key={`tokenbtn-${i}`} className="team-member">
                                                        <PlayerCardDisplay card={token} click={() => {
                                                            if (prepareToken && prepareToken.token_id === token.token_id) {
                                                                setPrepareToken(null)
                                                            } else {
                                                                setCollection(token.collection as CollectionSlug)
                                                                setLoadingToken(true)
                                                                setPrepareToken(null)
                                                                setTimeout(() => {
                                                                    setLoadingToken(false)
                                                                    setPrepareToken(token)
                                                                }, 300)
                                                            }
                                                        }} />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>

                                    {myTokens.length > 0 ? (
                                        <div id="ready-controls" className="relative ready">
                                            <div className='animate__animated animate__fadeInUp'>
                                                <span className="blur-backdrop">
                                                    <GetReadyButtonFrame className='button-frame' />
                                                    <IonButton 
                                                        color={'transparent'} 
                                                        onClick={() => {
                                                            if (room) {
                                                                ready(user, room, myTokens);
                                                            }
                                                        }}
                                                        style={{ width: '222px' }}
                                                    >
                                                        <span className="dib pt2 mt1 gladiator-arena">Ready</span>
                                                    </IonButton>
                                                </span>
                                            </div>
                                        </div>
                                    ) : null}

                                </div>
                            </div>
                        </div>
                        <div id='ready-controls-container' className={`w-100 flex justify-center items-center ${prepareToken && myTokens.length >= 3 && !isReady(prepareToken) ? `o-40 pointer-events-none` : ``}`} data-tokens={myTokens.length} data-required-tokens={requiredTokens}>
                            {/* Card Preview controls */}
                            {!loadingToken && prepareToken?.token_id ? (
                                <div id="team-controls" className={`relative animate__animated animate__fadeIn ${isReady(prepareToken) ? `remove` : `add`} `}>
                                    <GetReadyButtonFrame className='button-frame' />
                                    <IonButton
                                        color={`transparent`}
                                        expand='block'
                                        disabled={myTokens.length >= 3 && !isReady(prepareToken)}
                                        onClick={() => {
                                            toggleTokenSelect(prepareToken)
                                        }}
                                        style={{ width: '222px' }}
                                    >
                                        {isReady(prepareToken) ? (
                                            <span className="dib pt2 mt1 gladiator-arena">Remove</span>
                                        ) : (
                                            <span className="dib pt2 mt1 gladiator-arena">Add to Team</span>
                                        )}
                                    </IonButton>
                                </div>
                            ) : null}

                        </div>
                    </div>
                </div>
                <div id="tilt-js-get-ready" />
            </IonContent>
        </IonPage>
    );
};

export default GetReady;
